import { useState, useEffect } from "react"

export default function TypeWriter ({ text, speed = 10 }) {
    const [displayedText, setDisplayedText] = useState("")

    const formatText = (input) => {
      return input
      .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
      .replace(/_(.*?)_/g, '<i>$1</i>')
      .replace(/```(.*?)```/gs, '<code>$1</code>')
      .replace(/###/g, '<br/>')
      .replace(/- (.*?)(\n|$)/g, '<li>$1</li>')
      .replace(/(\d+)\. (.*?)(\n|$)/g, '<li>$2</li>')
      .replace(/(<li>.*?<\/li>)+/g, '<ul>$&</ul>') 
      .replace(/#### (.*?)(\n|$)/g, '<h4>$1</h4>') 
      .replace(/### (.*?)(\n|$)/g, '<h3>$1</h3>') 
      .replace(/## (.*?)(\n|$)/g, '<h2>$1</h2>') 
      .replace(/# (.*?)(\n|$)/g, '<h1>$1</h1>')
    }
    
    useEffect(() => {
      let index = 0

      setDisplayedText('')
      
      const interval = setInterval(() => {
        if (index < text.length - 1) {
          setDisplayedText((prev) => prev + text[index])

          index++
        } else {
          clearInterval(interval)
        }
      }, speed)
  
      return () => clearInterval(interval)
    }, [text, speed])
  
    return <p style={{
      fontSize: 22,
      color: '#fff',
      fontWeight: 800
    }} dangerouslySetInnerHTML={{ __html: formatText(text[0] + displayedText) }} />
  }