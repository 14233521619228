import React from "react"
import { 
    Result,
    Button,
    Typography,
    Popconfirm
} from 'antd'
import { 
    SmileOutlined
} from '@ant-design/icons'
import { Locale } from "../../helpers/locale"

export default function PageFourSetup ({ handlePage = () => {}, token, state }) {
    const handleBackLogin = () => {
        handlePage(5)
    }

    return (
        <>
            <Result 
                icon={<SmileOutlined />}
                title={Locale('almost there... copy and paste in somewhere safe')}
                children={<>
                    <Typography.Text>
                        <Typography.Title level={2}>
                            {Locale('your token')}
                        </Typography.Title>
                        <Typography.Paragraph>
                            <code>
                                {token} 
                            </code>
                        </Typography.Paragraph>
                    </Typography.Text>
                    <Typography.Text>
                        <Typography.Title level={2}>
                            {Locale('your private key')}
                        </Typography.Title>
                        <Typography.Paragraph>
                            <code>
                                {state.private_key} 
                            </code>
                        </Typography.Paragraph>
                    </Typography.Text>
                </>}
            />
            <Popconfirm
                title={Locale('go to login')}
                description={Locale('do you have copied token and private key and paste in a safe place?')}
                onConfirm={handleBackLogin}
                okText={Locale('yes')}
                cancelText={Locale('no')}
            >
                <Button style={{ display: 'block', margin: '0 auto', marginTop: -15 }} type="primary">{Locale('login')}</Button>
            </Popconfirm>
        </>
    )
} 