const localeStrings = {
    "pt-BR": {
        email: 'email',
        password: 'senha',
        login: 'entrar',
        cancel: 'cancelar',
        accept: 'aceitar',
        save: 'Salvar',
        name: 'Nome',
        actions: 'Ações',
        type: 'tipo',
        description: 'Descrição',
        environments: 'Ambientes',
        environment: 'Ambiente',
        normal: 'Comum',
        close: 'Fechar',
        production: 'Produção',
        homolog: 'Homologação',
        dev: 'Desenvolvimento',
        user: 'Usuário',
        port: 'Porta',
        created: 'Criada',
        edited: 'Editada',
        exclude: 'Excluir',
        list: 'Listar',
        create: 'Criar',
        delete: 'Deletar',
        connection: 'Conexão',
        authentication: 'Autenticação',
        response: 'Resposta',
        'with success': 'Com sucesso',
        'required fields': 'Campos obrigatórios',
        yes: 'Sim',
        no: 'Não',
        editing: 'Editando',
        back: 'Voltar',
        group: 'Grupo',
        settings: 'Configurações',
        'analyze with simple ai': 'Analisar com SimpleAI',
        default: 'Padrão',
        custom: 'Personalizado',
        // ini login page
        'has the serial? click here': 'Possui o numero de serial? clique aqui',
        'dont registered yet? get a test here': 'Nao tem um cadastro? Solicite um teste aqui',
        'email and password required': 'Email e senha obrigatórios',
        'terms of use': 'termos de uso',
        'i accept the terms of use and privacy': 'Aceito os termos de uso e de Privacidade',
        'welcome to simpletool, our cloud-based software development tools system. before using our services, we ask that you carefully read these terms and conditions of use. by accessing and using our system, you agree to comply with these terms and conditions, as well as all applicable laws and regulations. if you do not agree with these terms, please refrain from using our services.': 'Bem-vindo ao simpletool, nosso sistema de ferramentas de desenvolvimento de software em nuvem. Antes de utilizar nossos serviços, solicitamos que você leia atentamente estes Termos e Condições de Uso. Ao acessar e utilizar nosso sistema, você concorda em cumprir estes termos e condições, bem como todas as leis e regulamentos aplicáveis. Se você não concorda com estes termos, solicitamos que não utilize nossos serviços.',
        'simpletool services': 'Serviços do simpletool',
        'simpletool is a system with various cloud-based software development tools that run directly on your own server. our online platform provides an intuitive interface for creating and managing your software.': 'O simpletool é um sistema que possui diversas ferramentas de desenvolvimento de software em nuvem que rodam diretamente em seu próprio servidor. Nossa plataforma online oferece uma interface intuitiva para a criação e gerenciamento de seu software.',
        'you are solely responsible for the content you create and the proper use of simpletool.': 'Você é o único responsável pelo conteúdo que cria e pela utilização adequada do simpletool.',
        'data security': 'Segurança dos Dados',
        'we have implemented measures to protect the data transmitted and stored in our system, such as encryption, access tokens, and others.': 'Implementamos medidas para proteger os dados transmitidos e armazenados em nosso sistema, como: criptografia, token de acesso entre outros.',
        'we do not have access to sensitive information from your server or the data transmitted. all content and data are stored on your own server.': 'Não temos acesso a informações delicadas do seu servidor ou aos dados trafegados. Todo o conteúdo e dados são armazenados em seu próprio servidor.',
        'we recommend that you keep your passwords and access keys in a secure location and ensure your software and systems are updated to maintain the security of connections and data.': 'Recomendamos que você mantenha suas senhas e chaves de acesso em local seguro, além de manter seu software e sistemas atualizados para garantir a segurança das conexões e dos dados.',
        'payments': 'Pagamentos',
        'we offer monthly and annual subscription plans for using simpletool.': 'Oferecemos planos de assinatura mensal e anual para o uso do simpletool.',
        'in case of payment delinquency for a period of 5 days, we reserve the right to block access to simpletool. this may result in service downtime until the situation is resolved.': 'Em caso de inadimplência com os pagamentos por um período de 5 dias, reservamo-nos o direito de bloquear o acesso ao simpletool. Isso pode resultar na inoperância dos serviços prestados até que a situação seja regularizada.',
        'intellectual property': 'Propriedade intelectual',
        'all content provided on simpletool, including text, graphics, logos, images, and software, is the exclusive property of our company or our licensors and is protected by copyright laws and other intellectual property laws.': 'Todo o conteúdo disponibilizado no simpletool, incluindo textos, gráficos, logotipos, imagens e software, é de propriedade exclusiva de nossa empresa ou de nossos licenciadores, e está protegido por leis de direitos autorais e outras leis de propriedade intelectual.',
        'you are not permitted to store, reproduce, distribute, modify, publicly display, create derivative works, or commercially exploit any part of the content on simpletool without our prior written authorization.': 'Não há permissão para armazenar, reproduzir, distribuir, modificar, exibir publicamente, criar trabalhos derivados ou explorar comercialmente qualquer parte do conteúdo do simpletool sem nossa autorização prévia por escrito.',
        'limitation of liability': 'Limitação de Responsabilidade',
        'simpletool is provided "as is," and we make no express or implied warranties regarding its suitability for a specific purpose, performance, security, or reliability.': 'O simpletool é fornecido "no estado em que se encontra", e não oferecemos garantias expressas ou implícitas sobre sua adequação a um propósito específico, desempenho, segurança ou confiabilidade.',
        'we are not liable for any direct, indirect, incidental, consequential, or punitive damages resulting from the use or inability to use simpletool.': 'Não nos responsabilizamos por quaisquer danos diretos, indiretos, incidentais, consequenciais ou punitivos decorrentes do uso ou da impossibilidade de uso do simpletool.',
        'changes to the terms and conditions': 'Alterações nos Termos e Condições',
        'we reserve the right to modify these terms and conditions of use at any time. any changes will be published in our system and take effect immediately upon publication.': 'Reservamo-nos o direito de modificar estes Termos e Condições de Uso a qualquer momento. Quaisquer alterações serão publicadas em nosso sistema e entrarão em vigor imediatamente após a publicação.',
        'it is your responsibility to periodically review these terms and conditions to stay informed of any changes. continued use of simpletool after changes are published implies your acceptance of these modifications.': ' É sua responsabilidade revisar periodicamente estes termos e condições para estar ciente de quaisquer alterações. O uso contínuo do simpletool após a publicação de alterações implica na sua aceitação dessas modificações.',
        'account termination': 'Encerramento de Conta',
        'you may terminate your account with us at any time; however, no refunds will be issued for payments made. your access and service availability will continue until the last paid day.': 'Você pode encerrar sua conta conosco a qualquer momento, porém não há reembolso de quaisquer valores pagos e seu acesso e disponibilidade do serviço continuarão sendo fornecidos até o último dia pago.',
        'we reserve the right to terminate your account or restrict your access to simpletool if you violate these terms and conditions or if suspicious or fraudulent activities are associated with your account.': 'Reservamo-nos o direito de encerrar sua conta ou restringir seu acesso ao simpletool caso venha violar estes termos e condições ou se houver atividades suspeitas ou fraudulentas relacionadas à sua conta.',
        'general provisions': 'Disposições Gerais',
        'these terms and conditions constitute the entire agreement between you and our company regarding the use of simpletool, superseding any prior agreements.': 'Estes termos e condições constituem o acordo completo entre você e nossa empresa em relação ao uso do simpletool, substituindo quaisquer acordos anteriores.',
        'our failure to exercise or enforce any right or provision of these terms does not constitute a waiver of such right or provision.': 'A falha de nossa parte em exercer ou fazer cumprir qualquer direito ou disposição destes termos não constitui renúncia a esse direito ou disposição.',
        'if any provision of these terms and conditions is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.': 'Caso alguma disposição destes termos e condições seja considerada inválida ou inexequível, as demais disposições permanecerão em pleno vigor e efeito.',
        'access our privacy policy (lgpd) for more information.': 'Acesse a nossa Politica de Privacidade (LGPD)',
        // end login page
        // ini container menu
        welcome: 'Bem-vindo(a)',
        replicator: 'Replicador',
        connections: 'Conexões',
        tools: 'Ferramentas',
        auth: 'autorizacao',
        groups: 'Grupos',
        'manage users': 'Gerenciar Usuários',
        users: 'Usuários',
        'users policy groups': 'Grupos de Permissão de Usuário',
        updates: 'Atualizações',
        logout: 'Sair',
        none: 'Nenhum',
        table: 'Tabela',
        // end container menu
        // ini replicator page
        'magic deploy': 'Magic Deploy',
        'define url and port by environment of magic deploy': 'Defina a URL e PORTA por ambiente do Magic Deploy',
        configurations: 'Configurações',
        'configurations of magic deploy updated successfully!': 'Configurações do Magic Deploy atualizadas com sucesso!',
        // end replicator page
        // ini connections page
        'liberate ip address to: ': 'Liberar acesso do DB ao IP: ',
        'migrations history': 'Historico de Migrations',
        'creating connection': 'Criando conexão',
        'editing connection:': 'Editando conexão:',
        'delete connection': 'Excluir conexão',
        'type the connection name to allow to exclude': 'Digite o nome da conexão para prosseguir com a exclusão',
        'db type': 'Tipo DB',
        'db name': 'Nome do DB',
        '* in sandbox you need to configure a dev environment for test': '* no Sandbox configure apenas o ambiente de "Desenvolvimento" para teste',
        'we encountered a error to exclude connection, try again': 'Houve um erro ao excluir a conexão, tente novamente',
        'connection exclude with success': 'Conexão excluída com sucesso',
        'environments where executed sql': 'Ambientes onde a migration foi executa',
        'executed sql code': 'Codigo SQL executado',
        // end connections page
        // ini updates page
        role: 'Tipo',
        'creating user': 'Criando usuário',
        'editing user': 'Editando usuário',
        'exclude user': 'Excluir usuário',
        'permission group': 'Grupo de Permissão',
        'user role': 'Tipo de usuário',
        'type the user name to allow to exclude': 'Digite o nome da conexão para prosseguir com a exclusão',
        'is easy to update, you only need to generate a new docker image from the dockerfile below:': 'Para atualizar é muito fácil, basta gerar uma nova imagem Docker à partir do Dockerfile abaixo: ',
        'user created successfully': 'Usuário criado com sucesso',
        'user edited successfully': 'Usuário editado com sucesso',
        'the name field is required and length between 3 and 20 characteres': 'O campo Nome deve conter entre 3 e 20 caracteres e é obrigatório!',
        'the email field is required and needs to be a valid email': 'O campo Email deve ser um endereço de e-mail válido e é obrigatório!',
        'the password field is required and length from 8 characteres': 'O campo Senha deve conter entre 8 ou mais caracteres e é obrigatório!',
        'the user role field is required': 'O campo Tipo de Usuário é obrigatório!',
        'the permission group field is required': 'O campo Grupo de Permissão é obrigatório!',
        'editing user:': 'Editando usuário: ',
        'user excluded successfully': 'Usuário excluído com sucesso!',
        'we encountered a error to exclude user or the user you are trying to exclude is the same as logged, try again': 'Houve um erro ao excluir o usuário ou o usuário é o mesmo logado, verifique e tente novamente',
        // end updates page
        // ini users policy page
        'users policies': 'Grupos de Permissão de Usuário',
        'new user policy': 'Criando novo grupo de permissão',
        'editing user policy: ': 'Editando grupo de permissão: ',
        'delete user policy': 'Excluir grupo de permissão',
        'the name is required': 'O nome do grupo de permissão é obrigatório',
        'type the user policy name to allow to exclude': 'Digite o nome do Grupo de Permissão para prosseguir com a exclusão',
        permissions: 'Permissoes',
        authorization: 'Autorização',
        // end users policy page
        // ini apis page
        'filter by group': 'Filtrar por grupo',
        'pull request waiting an action': 'Pull Request aguardando acao',
        'ok, i will verify': 'OK, irei verificar',
        'has pull request awaiting a merge or a desapprove for this api, verify and try again': 'Há Pull Requests aguardando merge ou desaprovação para essa API, verifique e tente novamente após não haver nenhum Pull Request pendente.',
        'the raw query of "list" needs to start with "select"': 'A RAW QUERY de "LIST" deve começar com "SELECT"',
        'the raw query of "get" needs to start with "select"': 'A RAW QUERY de "GET" deve começar com "SELECT"',
        'the raw query of "delete" needs to start with "delete" or "update"': 'A RAW QUERY de "DELETE" deve começar com "DELETE" ou "UPDATE"',
        'the field name is required': 'O campo nome é obrigatório',
        'the update is not possible because has pull requests opened, verify and try again': 'Não foi possível atualizar o API, há PRs em aberto, verifique e tente novamente',
        'api description': 'Descrição da API',
        'the cors protect your api verifying the header origin is allowed or not': 'O CORS protege suas APIs verificando se o header Origin é permitido ou não',
        'select cors': 'Selecionar CORS',
        'select acl': 'Selecione um ACL',
        'endpoint name': 'Nome do endpoint',
        'save the data of response on redis': 'Salvar dados de resposta no Redis (diminui o tempo de resposta da api)',
        'cache queries': 'Cachear queries',
        'use authentication of jwt and oauth2 (requires reverse authentication)': 'Usar autenticação JWT e OAUTH2 (requer reverse authentication)',
        'api actived/deactived': 'API ativa/desativada',
        'allow inject query string on sql (list/get)': 'Permitir injetar query string no SQL (List/Get)',
        'show errors': 'Mostrar erros',
        'show errors on apis response': 'Mostrar erros na resposta da API',
        'api name': 'Nome da API',
        'table name': 'Nome da tabela',
        'request': 'Requisição',
        'request configurations': 'Configurações da requisição',
        'you can create a raw query and pass the values with expression {variable_name}. if have authentication the system will try to fill in the variable and search the same name inside the session has been created with reverse authorization and if the variable is not finded the system will try to search in query string. you don\'t need a raw query to utilize query string.': 'Você pode criar uma RAW Query e passar valores com a expressão {nome_variavel}... caso tenha autenticação, o sistema irá tentar preencher a variável buscando O MESMO NOME dentro da sessão criada através do REVERSE AUTHORIZATION, caso não encontre, buscará na QUERY STRING da URL. É permitido sempre utilizar QUERY STRING independente de uma RAW Query',
        'raw query actived/deactived': 'Raw query ativada/desativada',
        'define the table field name to order': 'Defina o nome do campo para realizar a ordenação',
        'the default value is id': 'O valor padrão é id',
        'type of order by': 'Tipo order by',
        'define the order type': 'Defina o tipo de ordenação',
        pagination: 'Paginação',
        'pagination actived/deactived': 'Paginação ativada/desativada',
        'type the paramater exactly it will returned from db and press enter': 'Digite o parâmetro exatamente como irá retornar do banco de dados e dê enter',
        'response preview': 'Preview de resposta',
        'if has code in "dev mode" the "simple mode" is ignored': 'Quando houver código no "Dev Mode" o "Simple Mode" é ignorado.',
        'the code always end with a return {object} or return {array} to fill the response': 'O código deverá sempre terminar com um "return {Object}" ou "return [Array]", para conter um valor na resposta',
        'after change, click in "save code" to confirm and after save a pull request will be open': 'Após feita a modificação, clique em "salvar código"  e após salvar, será aberto um PR para aprovação ou não do código',
        '* in sanbox use only the environment "dev" for test': '* no Sandbox use apenas o ambiente de "desenvolvimento" para teste',
        'save code': 'Salvar código',
        'process nodejs variable': 'Variável process do NodeJS',
        'require nodejs variable': 'Variável require do NodeJS',
        'knex.js instance': 'Variável com instancia do Knex.js',
        '* is only returned the first register, utilize the ID on query': 'PS: Será retornado apenas o primeiro registro, por isso é aconselhável utilizar o ID do endpoint na query',
        'raw query actived/deactived': 'Raw query ativado/desativado',
        'configurations of request response': 'Configurações da resposta da requisição',
        'schema saved with success': 'Schema configurado com sucesso',
        ' for more details': ' para mais detalhes',
        'you can define a request schema to validate the request body fields are sended to api, see the docs ': 'Você pode definir um schema de requisição para fazer a validação dos campos e tipo de dados enviados à API, consulte a documentação do ',
        'select worker': 'Selecionar Worker',
        'endpoint actived/deactived': 'Endpoint ativado/desativado',
        'type the pull request description': 'Digite a descrição da Pull Request',
        'execute a worker after the endpoint processing ends': 'Execute um worker após finalizar o processamento do endpoint',
        'save schema': 'Salvar schema',
        'define a condition to update the info, exemple: id = {id} and user_id = {user_id}. the variables will be replaced by reverse authorization session or query string': 'Defina uma condição para o update da informação, exemplo: id = {id} AND user_id = {user_id}. As variáveis serão preenchidas através da sessão criada pelo REVERSE AUTH ou pela QUERY STRING',
        'exemple: id = {id} and user_id = {user_id}': 'exemplo: id = {id} AND user_id = {user_id}',
        'see response': 'Ver resposta',
        'we encountered an error to exclude API, try again': 'Houve um erro ao excluir a API, tente novamente',
        'api excluded with success': 'API excluída com sucesso',
        'type the api name to exclude': 'Digite o nome da API para prosseguir com a exclusão',
        // end apis page
        // ini workers page
        'has pull request opened for this worker, verify ant try again': 'Há Pull Requests aguardando merge ou desaprovação para esse Worker, verifique e tente novamente após não haver nenhum Pull Request pendente.',
        'pull request awaiting action': 'Pull Request aguardando ação',
        'creating new worker': 'Criando novo Worker',
        'worker name': 'Nome do worker',
        'show execution errors': 'Mostrar erros de execução',
        'worker description': 'Descrição do worker',
        'worker actived/deactived': 'worker ativado/desativado',
        'execute after': 'Executar depois',
        'has an error to exclude worker, try again': 'Houve um erro ao excluir a Worker, tente novamente',
        'worker excluded with success': 'Worker excluído com sucesso',
        'type the worker name to exclude': 'Digite o nome da Worker para prosseguir com a exclusão',
        // end workers page
        // ini cors page
        'creating new cors': 'Criando novo CORS',
        'the cors helps to protect the apis from origins not allowed': 'O CORS ajuda a proteger suas APIs de requisições de origem não permitida',
        'the request is only allowed for the origins listed below': 'A requisição será permitida para qualquer origem digitada abaixo.',
        'allowed origins': 'Origens permitidas',
        'type the url exactly how is coming in origin and press enter': 'Digite a URL exatamente como é e dê ENTER',
        'the origin needs to start with http:// or https:// and be a valid url': 'A origem deve começar com http:// ou https:// e ser uma URL válida',
        'origin': 'Origem',
        'name of cors': 'Nome do CORS',
        'cors excluded with success': 'Cors excluído com sucesso',
        'type the cors name to exclude': 'Digite o nome do Cors para prosseguir com a exclusão',
        // end cors page
        // ini auth page
        'configurations of authorization updated with success': 'Configurações de Autorização atualizadas com sucesso',
        'revoke token error': 'Não foi possível revogar o token, tente novamente em instantes',
        'configurations of authorization': 'Configurações de autorização',
        'jwt authorization': 'Autorização JWT',
        '* this is the automatic mode of jwt authorization of simplerest, if your system don\'t have authentication... if the system have authentication utilize "reverse authorization"': '* Esse é o modo automático de autorização JWT do SimpleREST, caso seu sistema não tenha autenticação... caso seu sistema já possua autenticação, utilizar o "Reverse Authorization"',
        'search column': 'Coluna de busca',
        'column to verify user exists': 'Coluna que verifica se o usuario existe',
        'table search': 'Nome da tabela',
        'password column': 'Coluna de senha',
        'column to compare the password hash': 'Coluna que compara hash de senha',
        'password cryptograph': 'Criptografia da Senha',
        'allow authorization by external token': 'Permite fazer autorização reversa através do token gerado externamente',
        'session time': 'Tempo de sessão',
        'in seconds': 'Em segundos',
        'default authorization to access yous apis': 'Defina o padrão de autorização de acesso às suas APIs',
        // end auth page
        // ini logs page
        'logs not found': 'Nenhum log encontrado',
        // end logs page
        // ini pull request page
        'filter by api/worker': 'Filtrar por api/worker',
        'approved with success': 'aprovada com sucesso',
        'deaproved with success': 'desaprovada com sucesso',
        'deaprove': 'Desaprovar',
        'are you sure you want to deaprove the merge of changes?': 'Tem certeza que deseja desaprovar o merge das modificações acima?',
        'approve': 'Aprovar',
        'are you sure you want to approve the merge of changes?': 'Tem certeza que deseja aprovar e realizar o merge das modificações acima?',
        // end pull request page
        // ini group page
        'creating new group': 'Criando novo grupo',
        'groups': 'Grupos',
        'group name': 'Nome do grupo',
        'group deleted with success': 'Grupo excluído com sucesso',
        'type the group name to exclude': 'Digite o nome do grupo para prosseguir com a exclusão',
        // end group page
        // ini acl page
        'creating new acl': 'Criando novo grupo',
        'acls': 'ACLs',
        'acl name': 'Nome do ACL',
        'acl deleted with success': 'ACL excluído com sucesso',
        'type the acl name to exclude': 'Digite o nome do ACL para prosseguir com a exclusão',
        'the acl (access control level) is to protect the api endpoints if the user are trying to request don\'t have the permissions to do that': 'O ACL (Access Control Level) exerce a função de proteger os endpoints de suas APIs em que o usuário não tem as devidas permissões para acessa-la',
        'the user needs all the permissions below to access the api': 'O usuário PRECISA ter TODAS as permissões para conseguir acesso à API',
        'common permissions': 'Permissões Comuns',
        'type the parameter exactly as it is and press enter': 'Digite o parâmetro exatamente como é para a comparação e dê enter',
        'root permissions': 'Super Permissões',
        'if user has any of the root permissions below he will be allowed to access the api': 'Se o usuário possuir alguma das super permissões, ele consegue acesso à API, exemplo: admin, support',
        'type the acl name to exclude': 'Digite o nome do ACL para prosseguir com a exclusão',
        // end acl page
        // ini keys page
        'keys updated with success': 'Chaves atualizadas com sucesso',
        'keys created with success': 'Chaves criadas com sucesso',
        'email configurations': 'Configurações de email',
        'from': 'De',
        'zenvia configurations': 'Configurações da Zenvia',
        'know how to get a token': 'Saiba como conseguir o Token',
        'clicking here': 'clicando aqui',
        'slack configurations': 'Configurações do Slack',
        'stripe configurations': 'Configurações da Stripe',
        'mercado pago configurations': 'Configurações do Mercado Pago',
        'aws configurations': 'Configurações do AWS',
        'know how to get credentials': 'Saiba como conseguir as credenciais', 
        'region': 'Regiao',
        'default account region': 'Região padrão da conta',
        'the keys can be used by a variable "simplekeys"': 'As chaves podem ser usadas com a variável "simplekeys"',
        'new key': 'Nova Key',
        add: 'Adicionar',
        // end keys page
        // ini migrations page
        'finalize migration': 'Finalizar Migration',
        'are you sure you want to finalize migration?': 'Tem certeza que deseja finalizar a migration?',
        'migration finalized success': 'Migration finalizada com sucesso',
        'the migration allow you to run scripts in the db, like update tables, create tables...': 'As migrations servem para rodar scripts no banco de dados, como: atualizar tabelas, criar tabelas e etc...',
        'migration executed with success': 'Solicitação de execução de Migration efetuada com sucesso',
        'migration created with success': 'Migration criada com sucesso',
        'define the environments where migration will be executed': 'Defina os ambientes em que a migration será executada',
        'execute migration': 'Executar migration',
        'are you sure you need to execute migration in the selected environments?': 'Tem certeza que deseja executar a migration nos ambientes selecionados?',
        'creating new migration': 'Criando nova migration',
        'write the SQL below to execution is not possible to edit after save': 'Digite os códigos SQL para execução, não é possível editar após salvar',
        'migration excluded with success': 'Migration excluído com sucesso',
        'type the name of migration to exclude': 'Digite o nome da Migration para prosseguir com a exclusão',
        'visualize': 'Visualizar',
        pending: 'pendente',
        error: 'Erro',
        success: 'Sucesso',
        executing: 'Executando',
        version: 'Versão',
        // end migrations page
        // ini tasks page
        stoped: 'Pausado',
        expression: 'Expressão',
        'creating new task': 'Criando nova task',
        'name of task': 'Nome da task',
        'the expression field is required': 'A expressão é obrigatória',
        'the worker is required': 'O worker é obrigatório',
        'this generator will help you, click here': 'Esse gerador de expressão vai ajuda-lo',
        'define tasks to execute automatically': 'Defina tasks para serem executadas automaticamente',
        'has an error to exclude task, try again': 'Houve um erro ao excluir a task, tente novamente',
        'task excluded with success': 'task excluído com sucesso',
        // end tasks page
        // ini rollback modal
        'use this version': 'Usar essa versão',
        'rollbacked with success': 'rollback executado com sucesso',
        'current version': 'versão atual',
        'no rollbacks to display': ' nenhum rollback para mostrar',
        // end rollback modal
        // ini tutorial page
        'configure magic deploy': 'Configure o Magic Deploy',
        'configure magic deploy for syncing with your server (required)': 'Configure o magic deploy para sincronizar os dados com o seu servidor (obrigatório)',
        'create a group': 'Crie um grupo',
        'create a group to organize your apis and workers (optional)': 'Crie um grupo para organizar suas APIs e WORKERS (opcional)',
        'create a db connection': 'Crie uma conexão com o BD',
        'create a db connection to use with your apis and workers (required)': 'Crie uma conexão para usar em suas APIs e Workers (obrigatório)',
        'create a api': 'Crie uma API',
        'create a api to use with your system or system of partners': 'Crie uma API para usar com seus sistemas ou para sistemas de terceiros',
        'create a worker': 'Crie um Worker',
        'create a worker to process data in background': 'Crie um Worker para processar dados em background',
        'explore all simpletool features': 'Explore todas as ferramentas do SimpleTOOL',
        'need a support?': 'Precisa de ajuda?',
        'click here': 'clique aqui',
        'follow the steps below to begin with simpletool': 'Siga os passos abaixo para começar com o SimpleTOOL',
        // end tutorial page
        // ini setup page
        'type the activation serial': 'Digite o serial de ativação',
        'verify serial': 'Verificar Serial',
        'infos': 'Cad. Informações',
        'user': 'Cad. Usuario',
        'finalization': 'Finalização',
        'back to login': 'Voltar ao login',
        'type the infos below': 'Preencha as informações abaixo',
        'company name': 'Razão Social/Nome',
        'ein': 'CNPJ/CPF',
        'phone': 'Telefone',
        'address': 'Endereço',
        continue: 'Continuar',
        'the ein is required': 'O CPF ou CNPJ é obrigatório',
        'the company name is required and have a length between 1 and 20': 'O nome deve ter entre 1 e 20 caracteres e é obrigatório',
        'the phone is required': 'O telefone é obrigatório',
        'the address is required': 'O endereço é obrigatório',
        'type the infos below to create adm user': 'Preencha as informações para criar o usuário ADM',
        'name': 'Nome',
        'finalize': 'Finalizar',
        'this email is not valid or we have in our db': 'Esse email não é válido ou já está em nossa base de dados',
        'the name is required and have a length between 3 and 12': 'O campo nome deve conter entre 3 e 12 caracteres e é obrigatório',
        'the email is required and need be valid': 'O campo email deve ser um endereço de e-mail válido e é obrigatório',
        'the password is required and have a length between 8 or more characters': 'O campo senha deve conter entre 8 ou mais caracteres e é obrigatório',
        'almost there... copy and paste in somewhere safe': 'Quase lá... Copie e cole em algum lugar seguro',
        'your token': 'Seu Token',
        'your private key': 'Sua Private Key',
        'go to login': 'Ir para o login',
        'do you have copied token and private key and paste in a safe place?': 'Você copiou e armazenou o token e a private key em um lugar seguro?',
        // end setup page
        // ini settings page
        'settings updated with success': 'Configurações atualizadas com sucesso',
        'client configurations': 'configurações do cliente',
        'open pull request on every change of apis and workers (dev mode)': 'Abrir um Pull Request a cada nova mudança em apis e worker (dev mode)',
        'settings': 'Configurações',
        '* renewed each first day of month at 00:00': '* renovado todo primeiro dia do mês às 00:00 ',
        // end settings page
        'execute after login': 'Executar após o login',
        'you can set additional session data after login': 'Você pode adicionar informações extras na sessão após o login',
        'set roles, user custom data, whatever you want, but don\'t finish with "return"': 'Adicione roles, custom data, o que você precisar, mas não finalize com um "return"',
        'object with user info': 'Objeto com informações de usuário',
        'array of roles': 'array com as roles',
        'an error have occured on revoke token, please try again': 'Não foi possível revogar o token, tente novamente em instantes',
        'token revoked with success': 'Token revogado com sucesso!'
    }
}

const setLocale = (language = 'pt-BR', setLang) => {
    localStorage.setItem('@sys_language', language)

    setLang(language)
}

const Locale = (text, uppercase = true) => {
    const language = localStorage.getItem('@sys_language') || 'pt-BR',
        localeString = localeStrings[language] && localeStrings[language][text.toLowerCase()] || text

        return uppercase && localeString.charAt(0).toUpperCase() + localeString.slice(1) || localeString
}

export {
    Locale,
    setLocale
}