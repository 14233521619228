import React, { useState, useEffect } from "react"
import { 
    Tag,
    Button,
    Divider,
    Switch,
    Tooltip,
    Form,
    Input,
    Tabs,
    Select,
    Col,
    Row,
    Typography
} from 'antd'
import {
    CheckOutlined,
    CloseOutlined,
    InfoCircleOutlined,
    ThunderboltOutlined,
    CoffeeOutlined
} from '@ant-design/icons'
import Editor from '@monaco-editor/react'
import { Notification } from "../.."
import { Locale } from '../../../helpers/locale'

const { Item } = Form

export default function TabPut ({ handleValue = () => {}, values = {}, handleAI = () => {}, isEdit = false, appId = null, endpointName = null, endpointName2 = null, workers = [] }) {
    const [endpointDisabled, setEndpointDisabled] = useState(values?.request?.status || false),
    [saveButtonDisabled, setSaveButtonDisabled] = useState(true),
    [saveSchemaButtonDisabled, setSaveSchemaButtonDisabled] = useState(true),
    [aiButtonDisabled, setAIButtonDisabled] = useState(true),
    [editorCodePrd, setEditorCodePrd] = useState(''),
    [editorCodeHml, setEditorCodeHml] = useState(''),
    [editorCodeSdb, setEditorCodeSdb] = useState(''),
    [editorCodeDev, setEditorCodeDev] = useState(''),
    [editorCodeRequest, setEditorCodeRequest] = useState('')

    const handleEnableEndpoint = () => {
        const newValue = !endpointDisabled ? true : false
        setEndpointDisabled(newValue)
        handleValue({ target: { value: Number(newValue) } }, 'request.update.status')
    }

    let hasNotified = false

    const draggerProps = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        accept: 'application/json',
        showUploadList: false,
        action: "#",
        onChange(info) {
            hasNotified = false

            const fr = new FileReader()

            fr.addEventListener("load", e => {
                const json = JSON.parse(fr.result)

                handleValue({ target: { value: json } }, 'request.update.schema')
                if(hasNotified) return 

                Notification({
                    description: Locale('schema saved with success'),
                    title: 'Upload JSON'
                })

                hasNotified = true
            })

            fr.readAsText(info.file.originFileObj)
        }
    }

    const handleSaveCode = () => {
        const codePrd = values?.request?.dev_mode?.prd.code
        if((editorCodePrd != codePrd)) handleValue(editorCodePrd, 'request.update.dev_mode.prd.code')

        const codeHml = values?.request?.dev_mode?.hml.code
        if((editorCodeHml != codeHml)) handleValue(editorCodeHml, 'request.update.dev_mode.hml.code')

        const codeSdb = values?.request?.dev_mode?.sdb.code
        if((editorCodeSdb != codeSdb)) handleValue(editorCodeSdb, 'request.update.dev_mode.sdb.code')

        const codeDev = values?.request?.dev_mode?.dev.code
        if((editorCodeDev != codeDev)) handleValue(editorCodeDev, 'request.update.dev_mode.dev.code')

        setSaveButtonDisabled(true)
        setAIButtonDisabled(false)

        if(!(editorCodePrd?.length 
            || editorCodeHml?.length 
            || editorCodeSdb?.length 
            || editorCodeDev?.length)) 
            setAIButtonDisabled(true)
    }

    const handleSaveSchema = () => {
        handleValue({ target: { value: editorCodeRequest } }, 'request.update.schema')

        setSaveSchemaButtonDisabled(true)
    }

    useEffect(() => {
        setEditorCodePrd('')
        setEditorCodeHml('')
        setEditorCodeSdb('')
        setEditorCodeDev('')
    }, [appId])
    
    useEffect(() => {
        setEndpointDisabled(Boolean(values?.request?.status))

        setSaveButtonDisabled(true)
        setSaveSchemaButtonDisabled(true)
        
        if((values?.request?.dev_mode?.prd.code[0])) {
            const codePrd = values?.request?.dev_mode?.prd.code
            if((editorCodePrd != codePrd)) setEditorCodePrd(codePrd)
        }

        if((values?.request?.dev_mode?.hml.code[0])) {
            const codeHml = values?.request?.dev_mode?.hml.code
            if((editorCodeHml != codeHml)) setEditorCodeHml(codeHml)
        }

        if((values?.request?.dev_mode?.sdb.code[0])) {
            const codeSdb = values?.request?.dev_mode?.sdb.code
            if((editorCodeSdb != codeSdb)) setEditorCodeSdb(codeSdb)
        }

        if(values?.request?.dev_mode?.dev.code[0]) {
            const codeDev = values?.request?.dev_mode?.dev.code
            if((editorCodeDev != codeDev)) setEditorCodeDev(codeDev)
        }
    }, [values, appId])

    const tabsEnv = [
        <>
            <h3 style={{ textAlign: 'left' }}>
                {Locale('production')} <Button 
                    style={{ float: 'right' }}
                    onClick={() => handleAI(editorCodePrd)}
                    disabled={aiButtonDisabled}    
                    color={'pink'}
                    variant={'solid'}
                >{Locale('analyze with simple ai')}</Button>
            </h3>
            <Editor
                language='javascript'
                value={editorCodePrd || ''}
                theme='vs-dark'
                height="75vh"  
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodePrd(newValue)
                }}
                options={{
                    autoIndent: "full"
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                {Locale('homolog')} <Button 
                    style={{ float: 'right' }}
                    onClick={() => handleAI(editorCodeHml)}
                    disabled={aiButtonDisabled}    
                    color={'pink'}
                    variant={'solid'}
                >{Locale('analyze with simple ai')}</Button>
            </h3>
            <Editor
                language='javascript'
                value={editorCodeHml || ''}
                theme='vs-dark'
                height="75vh"  
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodeHml(newValue)
                }}
                options={{
                    autoIndent: "full"
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                Sandbox <Button 
                    style={{ float: 'right' }}
                    onClick={() => handleAI(editorCodeSdb)}
                    disabled={aiButtonDisabled}    
                    color={'pink'}
                    variant={'solid'}
                >{Locale('analyze with simple ai')}</Button>
            </h3>
            <Editor
                language='javascript'
                value={editorCodeSdb || ''}
                theme='vs-dark'
                height="75vh"  
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodeSdb(newValue)
                }}
                options={{
                    autoIndent: "full"
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                {Locale('dev')} <Button 
                    style={{ float: 'right' }}
                    onClick={() => handleAI(editorCodeDev)}
                    disabled={aiButtonDisabled}    
                    color={'pink'}
                    variant={'solid'}
                >{Locale('analyze with simple ai')}</Button>
            </h3>
            <Editor
                language='javascript'
                value={editorCodeDev || ''}
                theme='vs-dark'
                height="75vh"  
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodeDev(newValue)
                }}
                options={{
                    autoIndent: "full"
                }}
            />
        </>
    ]

    const tabs = [
        <>
            <Divider orientation="left">
                {Locale('request')}
                <Tooltip placement="topLeft" title={Locale('request configurations')}>
                    <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                </Tooltip>    
            </Divider>
            
            <div id={'request-params'}>
                <Item label={(<>
                    {'Schema'}
                    <Tooltip placement="topLeft" title={(<>{Locale('you can define a request schema to validate the request body fields are sended to API, see the docs ')}<a href="https://ajv.js.org/json-schema.html" target={'_BLANK'}>AJV</a>{Locale(' for more details')}</>)}>
                        <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                    </Tooltip>
                    </>)}
                >
                    <Editor
                        language='json'
                        value={editorCodeRequest || ''}
                        theme='vs-dark'
                        height="40vh" 
                        onChange={(newValue) => {
                            if(saveButtonDisabled) {
                                setSaveSchemaButtonDisabled(false)
                            }

                            setEditorCodeRequest(newValue)
                        }}
                        options={{
                            autoIndent: "full"
                        }}
                    />
                    <Button style={{ marginTop: 10, float: 'right' }} disabled={saveSchemaButtonDisabled} type="default" shape="round" onClick={handleSaveSchema}>{Locale('save schema')}</Button>
                    {/* <Dragger 
                        { ...draggerProps }
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Clique ou arraste e solte o arquivo .json</p>
                    </Dragger> */}
                    <Row
                        gutter={{
                            xs: 8,
                            sm: 16,
                            md: 24,
                            lg: 32,
                        }}
                    >
                        <Col className="gutter-row" span={12}>
                            <Item label={(<>
                                {'WHERE'}
                                <Tooltip placement="topLeft" title={Locale('define a condition to update the info, exemple: id = {id} AND user_id = {user_id}. The variables will be replaced by REVERSE AUTHORIZATION session or Query string')}>
                                    <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                                </Tooltip>
                                </>)}
                            >
                                <Input placeholder={Locale('exemple: id = {id} AND user_id = {user_id}')} onChange={(e) => handleValue(e, 'request.update.where')} value={values?.request?.where} />
                                <Typography.Paragraph style={{ marginTop: 10, cursor: 'pointer' }}>Default vars: {'{id}'}</Typography.Paragraph>
                            </Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <Item label={(<>
                                {'Worker'}
                                <Tooltip placement="topLeft" title={Locale('execute a worker after the endpoint processing ends')}>
                                    <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                                </Tooltip>
                            </>)}>
                                <Select
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder={Locale('select worker')}
                                    loading={workers?.data?.length > 0 ? false : true}
                                    options={workers?.data?.length > 0 && [ { _id: null, name: Locale('none') }, ...workers?.data ]?.map(worker => {
                                        return {
                                            label: worker.name,
                                            value: worker._id
                                        }
                                    }) || []} 
                                    onChange={(e) => handleValue({ target: { value: e }}, 'request.update.worker_id')}
                                    onInputKeyDown={(e) => e.preventDefault()}
                                    value={values?.request?.worker_id}
                                />
                            </Item>
                        </Col>
                    </Row>
                </Item>
            </div>
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                Dev Mode
            </h3>
            <h5 style={{ textAlign: 'left' }}>
                {/* Faça o clone do nosso <a href="https://github.com/codetec-repos/simplerest-dev-boilerplate">boilerplate</a> para auxiliar no desenvolvimento <br></br> */}
                {Locale('if has code in "Dev Mode" the "Simple Mode" is ignored')}<br></br>
                {Locale('The code always end with a return {object} or return {array} to fill the response')}<br></br>
                {Locale('after change, click in "save code" to confirm and after save a Pull Request will be open')}<br></br>
                {Locale('* in Sanbox use only the environment "Dev" for test')}
            </h5>
            <Input placeholder={Locale('type the Pull Request description')} value={values?.description} onChange={(e) => handleValue(e, 'request.update.description')} />
            <Typography.Paragraph style={{ marginTop: 10, cursor: 'pointer' }}>Default vars: 
                <Tooltip placement="topLeft" title={Locale('process NodeJS variable')}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>process</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={Locale('require NodeJS variable')}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>require</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={Locale('Knex.js instance')}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>db</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={<pre>{`
const {
    body,
    queryString,
    files: [],
    session,
    simpleApiCallService: {
        call: async (apiId : String, method : String('list' | 'get' | 'post' | 'put' | 'delete'), data = {} : Object, params = {} : Object) => {}
    }
} = simplerest
                    `}</pre>}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>simplerest</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={<pre>{`
const {
    S3Service,
    SimplePayments,
    PDFGenerator,
    CSVGenerator,
    DateFormat,
    SimpleNotifications
} = simplelib
                `}</pre>}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>simplelib</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={<pre>{`
const {
    email: {
        config: {
            host: "",
            port: "",
            secure: false
        },
        auth: { 
            user: "",
            pass: ""
        },
        emailParams: {
            from: ""
        }
    },
    slack: {
        auth: {
            oauthToken: ""
        }
    },
    zenvia: {
        auth: {
            token: ""
        }
    },
    stripe: {
        auth: {
            secretKey: ""
        }
    },
    mercadoPago: {
        auth: {
            accessToken: "",
            webhook: ""
        }
    },
    aws: {
        auth: {
            region: "",
            accessKey: "",
            secretKey: ""
        }
    }
} = simplekeys
                `}</pre>}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>simplekeys</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={<pre>{`
const {
    list: async (pattern = '*' : String) => {},
    get: async (key : String) => {},
    set: async (key : String, value : Object, expires : Integer) => {},
    del: async (key : String) => {}
} = cache
                `}</pre>}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>cache</Typography.Text>
                </Tooltip>,
                 <Tooltip placement="topLeft" title={<pre>{`
const {
    sendMessage: async (workerId | queueName = '', message = {} : Object)
} = simplequeue
                `}</pre>}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>simplequeue</Typography.Text>
                </Tooltip>
                <Tabs
                    defaultActiveKey="2"
                    items={[Locale('production'), Locale('homolog'), 'Sandbox', Locale('dev')].map((tabName, i) => {
                        return {
                            label: (
                            <span>
                                {tabName}
                            </span>
                            ),
                            key: i + 1,
                            children: tabsEnv[i],
                        }
                    })}
                />
            </Typography.Paragraph>
            <Button style={{ marginTop: 10, float: 'right' }} disabled={saveButtonDisabled} type="default" shape="round" onClick={handleSaveCode}>{Locale('save code')}</Button>
        </>
    ]

    return (
        <div>

            <h3 style={{ textAlign: 'left' }}>
                <Tag color="processing">PUT</Tag> {isEdit ? (endpointName2?.length > 0 ? `/v1/${appId}${endpointName?.length > 0 ? `|${endpointName}` : ''}/{id}/${endpointName2}` : `/v1/${appId}${endpointName?.length > 0 ? `|${endpointName}` : ''}/{id}`) : `/v1/{app_id}|{endpoint_name}/{id}/?{endpoint_name2}`}
                <Tooltip placement="topLeft" title={Locale('endpoint actived/deactived')}>
                    <Switch 
                        onChange={handleEnableEndpoint}
                        style={{ float: 'right' }}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={endpointDisabled}
                    />
                </Tooltip>
            </h3>
            
            <Tabs
                defaultActiveKey="2"
                items={[ThunderboltOutlined, CoffeeOutlined].map((Icon, i) => {
                    const tabName = i == 0 ? 'Simple Mode' : 'Dev Mode' 
                    return {
                        label: (
                        <span>
                            <Icon />
                            {tabName}
                        </span>
                        ),
                        key: i + 1,
                        children: tabs[i],
                    }
                })}
            />
        </div>
    )
} 