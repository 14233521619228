import React, { useEffect, useState } from "react"
import { 
    Modal,
    Col,
    Row
} from 'antd'
import { 
    AI
} from "../../../modules"
import { CloseOutlined } from "@ant-design/icons"
import TypeWriter from "../../type-writer"
import AILoading from "../../../ai-loading.gif"

export default function AIModal ({ title = '', open = false, handleOpen = () => {}, data = {} }) {
    const [dataAnalyze, setDataAnalyze] = useState(null),
        [loading, setLoading] = useState(true),
        [startedAnalyze, setStartedAnalyze] = useState(false),
        [dataToAnalyze, setDataToAnalyze] = useState('{}')

    const startAnalyze = async () => {
        try {
            const apiResponse = await AI.getAnalyzeCode({ data })

            setDataAnalyze({ ...apiResponse })

            console.log('analyze started')
        } catch (error) {
            console.log(error)            
        }
        
        setLoading(false)
        setStartedAnalyze(false)
    }

    useEffect(() => {
        if(!startedAnalyze) {
            setStartedAnalyze(true)
        }
    }, [open])

    useEffect(() => {
        if(startedAnalyze) {
            startAnalyze()
        }
    }, [startedAnalyze])

    return (
        <Modal
            title={title}
            centered
            open={open}
            onCancel={async () => { 
                handleOpen(false) 
            }}
            width={'100%'}
            height={'100%'}
            cancelButtonProps={{ 
                style: {
                    display: "none"
                }
            }}
            okButtonProps={{ 
                style: {
                    display: "none"
                }
            }}
            styles={{
                mask: {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    filter: 'blur(1rem)',
                    border: '3px solid #ff5472'
                },
                content: {
                    backgroundColor: 'inherit',
                    color: '#fff',
                    boxShadow: 'none'
                }
            }}
            closeIcon={<CloseOutlined style={{ color: '#fff', fontSize: 24 }} />}
        >
            {
                <Row 
                    style={{
                        height: '100vh'
                    }}
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                >
                    <Col span={24} style={{ alignContent: 'center', justifyContent: 'center' }}>
                        {loading ? (<></>
                            // <img src={AILoading} style={{
                            //     display: 'block',
                            //     margin: '0 auto'
                            // }} />
                        ) : (
                            <TypeWriter 
                                text={dataAnalyze?.content ?? ''}
                            />
                        )}
                    </Col>
                </Row>
            }
        </Modal>
    )
} 