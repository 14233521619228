const format = (date) => {
    const localLanguage = localStorage.getItem('@sys_language')

    const datetime = new Date(date),
        day = datetime.getUTCDate() < 9 ? `0${datetime.getUTCHours()}` : datetime.getUTCHours(),
        hours = datetime.getUTCHours() < 9 ? `0${datetime.getUTCHours()}` : datetime.getUTCHours(),
        minutes = datetime.getUTCMinutes() < 9 ? `0${datetime.getUTCMinutes()}` : datetime.getUTCMinutes(),
        month = datetime.getUTCMonth() < 9 ? `0${datetime.getUTCMonth() + 1}` : datetime.getUTCMonth() + 1

    date = `${day}/${month}/${datetime.getUTCFullYear()} ${hours}:${minutes}`

    if(localLanguage == 'en-US') {
        date = `${month}/${day}/${datetime.getUTCFullYear()} ${hours}:${minutes}`
    }

    return date
}

export default format