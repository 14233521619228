import API from '../../services/api'
import { URLS } from '../../environment.js'

const getServerIP = async () => {
    const { data: response } = await API.get(URLS.server_config.ip)

    return response?.data
}

const putSettings = async ({ data }) => {
    const { data: response } = await API.put(URLS.server_config.update, data)

    return response?.data
}

const getClientSettings = async ({}) => {
    const { data: response } = await API.get(URLS.server_config.list)

    return response?.data
}

const Server = {
    getServerIP,
    putSettings,
    getClientSettings
}

export default Server