import API from '../../services/api.js'
import { URLS } from '../../environment.js'

const getAnalyzeCode = async ({ data }) => {
    const { data: response } = await API.post(URLS.ai.analyzeCode, data)
    console.log(response)
    return response?.data
}

const AI = {
    getAnalyzeCode
}

export default AI