import React, { useState, useEffect } from 'react'
import { 
    Col,
    Row,
    Steps,
    Typography
} from 'antd'
import {
    PrincipalContainer
} from '../../containers'
import { useSWRConfig } from 'swr'
import { 
    Apps, 
    Groups, 
    ACL, 
    Connections, 
    Server,
    User,
    UserPolicies,
    PullRequest,
    Migration,
    Libs,
    Keys,
    Workers,
    AuthApp,
    Cors,
    Replicator,
    Tasks
} from '../../modules'
import { Locale } from '../../helpers/locale'

const { Title, Text } = Typography

export default function GetStartedPage ({ setCurrentPage }) {
    const { cache, mutate } = useSWRConfig()

    const [isMobile, setIsMobile] = useState(false),
        [isLoading, setIsLoading] = useState(false)

    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        }
    }

    const steps = [
        {
            title: Locale('configure magic deploy'),
            description: Locale('configure magic deploy for syncing with your server (required)'),
            status: 'process'
        },
        {
            title: Locale('create a group'),
            description: Locale('create a group to organize your APIs and WORKERS (optional)'),
            status: 'process'
        },
        {
            title: Locale('create a db connection'),
            description: Locale('create a db connection to use with your apis and workers (required)'),
            status: 'process'
        },
        {
            title: Locale('create a api'),
            description: Locale('create a api to use with your system or system of partners'),
            status: 'process'
        },
        {
            title: Locale('create a worker'),
            description: Locale('create a worker to process data in background'),
            status: 'process'
        },
        {
            title: Locale('explore all simpletool features'),
            description: <>
                <Text>{Locale('need a support?')}</Text><a> {Locale('click here')}</a>
            </>,
            status: 'process'
        }
    ]

    const startCache = async () => {
        try {
            setIsLoading(true)

            await mutate('@apps', Apps.getClientApps({}))
            await mutate('@connections', Connections.getClientConnections({}))
            await mutate('@groups', Groups.getClientGroups({}))
            await mutate('@acls', ACL.getClientACLs({}))
            await mutate('@users', User.getUsers({}))
            await mutate('@usersPolicies', UserPolicies.getUsersPolicies({}))
            await mutate('@server', Server.getServerIP({}))
            await mutate('@pull_requests', PullRequest.getPullRequests({}))
            await mutate('@migrations', Migration.getClientMigrations({}))
            await mutate('@libs', Libs.getClientLibs({}))
            await mutate('@keys', Keys.getClientKeys({}))
            await mutate('@workers', Workers.getClientWorkers({}))
            await mutate('@authApp', AuthApp.getClientAuthApp({}))
            await mutate('@cors', Cors.getClientCors({}))
            await mutate('@replicator', Replicator.getClientReplicator({}))
            await mutate('@tasks', Tasks.getClientTasks({}))
            await mutate('@settings', Server.getClientSettings({}))
        } catch (error) {
            console.log(error)            
        }
        
        setIsLoading(false)
    }

    useEffect(() => {
        if(!Object.keys(cache.get('@apps') ?? {})?.length) startCache()
    }, [])

    useEffect(() => {
        const handleResize = () => {
            const {
                width
            } = getWindowDimensions()

            if(width <= 768) setIsMobile(true)
            else setIsMobile(false)
        } 

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [localStorage.getItem('@user')])

    return (
        <PrincipalContainer 
            menuItemSelected='6' 
            setCurrentPage={setCurrentPage} content={
                <>
                    <Title style={{ marginBottom: 100 }}>
                        {Locale('follow the steps below to begin with simpletool')}
                    </Title>
                    <Row>
                        <Col style={{ overflow: 'scroll' }} span={24}>
                            <Steps
                                style={{ display: 'block', margin: '0 auto', width: isMobile ? '100%' : '30%' }}
                                current={5}
                                direction="vertical"
                                items={steps}
                            />
                        </Col>
                    </Row>
                </>
            } 
            isLoading={isLoading}
        />
    )
    
}