import React, { useState, useEffect } from 'react'
import { 
    Input, 
    Typography,
    Layout,
    Card,
    Col,
    Row,
    Button,
    Form,
    Modal
} from 'antd'
import { Auth } from '../../modules'
import { Notification } from '../../components'
import LOGO from '../../logo.png'
import Router from '../../router'
import EUAFlag from '../../united-states-flag-icon.svg'
import BrazilFlag from '../../brazil-flag-icon.svg'
import { Locale, setLocale } from '../../helpers/locale'

const { Title, Text } = Typography,
    { Content } = Layout

export default function LoginPage ({ setLogged, setCurrentPage }) {
    const [loginObj, setLoginObj] = useState({
        email: '',
        password: ''
    }),
    [termsModalOpened, setTermsModalOpened] = useState(false),
    [lang, setLang] = useState('')

    const handleLogin = async () => {
        if(!loginObj.email.length || !loginObj.password.length) return Notification({ 
            description: Locale('email and password required'),
            type: 'warning',
            title: 'Login'
        })

        const data = await Auth.login({ email: loginObj.email, password: loginObj.password })

        if(data?.data?.token?.length) setLogged(true)
    }

    const handleOpenTermsModal = (stat) => {
        setTermsModalOpened(stat)
    }

    useEffect(() => {
        if(localStorage.getItem('@token')) setLogged(true)

        const language = localStorage.getItem('@sys_language')

        setLang(language || 'pt-BR')
    }, [])

    useEffect(() => {
        localStorage.setItem('@sys_language', lang)
    }, [lang])

    return (
        <Layout key={lang}>
            <Content
                className="site-layout"
                style={{
                    background: 'radial-gradient(circle, rgba(174,196,238,1) 0%, rgba(154,154,154,1) 100%)'
                }}
            >
                <Row>
                    <Col span={24}>
                        <a style={{ textDecoration: 'none', color: '#000', float: 'right', marginRight: 10 }} onClick={() => setLocale('en-US', setLang)}>
                            <img src={EUAFlag} width={25} /> <span style={{ marginRight: 10 }}></span>
                        </a>
                        <a style={{ textDecoration: 'none', color: '#000', float: 'right', marginRight: 10 }} onClick={() => setLocale('pt-BR', setLang)}>
                            <img src={BrazilFlag} width={25} />
                        </a>
                    </Col>
                </Row>
                <Row justify='center' align="middle" style={{
                    minHeight: '100vh'
                }}>
                    <Col>
                        {/* <ArrowLeftOutlined 
                            style={{ 
                                cursor: 'pointer', 
                                fontSize: '20px', 
                                padding: 10 
                            }} 
                            onClick={() => {
                                setCurrentPage(Router.landingPage({ setCurrentPage, setLogged }))
                            }}
                        /> */}
                        <img style={{ margin: '0 auto', width: '20rem', display: 'block' }} src={LOGO} alt='logo imagem' />
                        <Card 
                        bordered={false}
                        style={{ 
                            width: 400, 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            background: 'transparent',
                            boxShadow: 'none'
                        }}>
                            <Title level={3}>{ Locale('Login') }</Title>
                            <Form style={{ width: 300 }}>
                                <Form.Item>
                                    <Input type={'email'} onChange={(e) => {
                                        let obj = loginObj
                                        obj.email = e.target.value
                                        setLoginObj({ ...obj })
                                    }} size={'large'} placeholder={Locale('Email')} />
                                </Form.Item>
                                <Form.Item>
                                    <Input.Password 
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleLogin()
                                            }
                                        }}
                                        onChange={(e) => {
                                            let obj = loginObj
                                            obj.password = e.target.value
                                            setLoginObj({ ...obj })
                                        }} 
                                        size={'large'} 
                                        placeholder={Locale('Password')}
                                    />
                                </Form.Item>
                            </Form>
                            <Modal 
                                title={ Locale('terms of use') + ' simpletool'}
                                centered
                                open={termsModalOpened}
                                onCancel={() => handleOpenTermsModal(false)}
                                width={1000}
                                onOk={() => setCurrentPage(Router.setupPage({ setCurrentPage, setLogged }))}
                                okText={Locale('i accept the terms of use and privacy')}
                                cancelText={Locale('cancel')}
                                cancelButtonProps={{ type: 'text' }}
                                bodyStyle={{ overflowY: 'scroll', maxHeight: 500 }}
                            >
                                <Text>
                                    {Locale('welcome to simpletool, our cloud-based software development tools system. Before using our services, we ask that you carefully read these Terms and Conditions of Use. By accessing and using our system, you agree to comply with these terms and conditions, as well as all applicable laws and regulations. If you do not agree with these terms, please refrain from using our services.')}
                                    <h5 style={{ marginTop: 10 }}>
                                        {Locale('simpletool services')}
                                    </h5>
                                    <b>1.1</b> {Locale('simpletool is a system with various cloud-based software development tools that run directly on your own server. our online platform provides an intuitive interface for creating and managing your software.')}<br></br>
                                    <b>1.2</b> {Locale('you are solely responsible for the content you create and the proper use of simpletool.')}<br></br>
                                    <h5 style={{ marginTop: 10 }}>
                                        {Locale('data security')}
                                    </h5>
                                    <b>2.1</b> {Locale('we have implemented measures to protect the data transmitted and stored in our system, such as encryption, access tokens, and others.')}<br></br>
                                    <b>2.2</b> {Locale('we do not have access to sensitive information from your server or the data transmitted. all content and data are stored on your own server.')}<br></br>
                                    <b>2.3</b> {Locale('We recommend that you keep your passwords and access keys in a secure location and ensure your software and systems are updated to maintain the security of connections and data.')}<br></br>
                                    <h5 style={{ marginTop: 10 }}>
                                        {Locale('Payments')}
                                    </h5>
                                    <b>3.1</b> {Locale('we offer monthly and annual subscription plans for using simpletool.')}<br></br>
                                    <b>3.2</b> {Locale('in case of payment delinquency for a period of 5 days, we reserve the right to block access to simpletool. This may result in service downtime until the situation is resolved.')}<br></br>
                                    <h5 style={{ marginTop: 10 }}>
                                        {Locale('intellectual property')}
                                    </h5>
                                    <b>4.1</b> {Locale('all content provided on simpletool, including text, graphics, logos, images, and software, is the exclusive property of our company or our licensors and is protected by copyright laws and other intellectual property laws.')}<br></br>
                                    <b>4.2</b> {Locale('You are not permitted to store, reproduce, distribute, modify, publicly display, create derivative works, or commercially exploit any part of the content on simpletool without our prior written authorization.')}<br></br>
                                    <h5 style={{ marginTop: 10 }}>
                                        {Locale('limitation of liability')}
                                    </h5>
                                    <b>5.1</b> {Locale('simpletool is provided "as is," and we make no express or implied warranties regarding its suitability for a specific purpose, performance, security, or reliability.')}<br></br>
                                    <b>5.2</b> {Locale('We are not liable for any direct, indirect, incidental, consequential, or punitive damages resulting from the use or inability to use simpletool.')}<br></br>
                                    <h5 style={{ marginTop: 10 }}>
                                        {Locale('changes to the terms and conditions')}
                                    </h5>
                                    <b>6.1</b> {Locale('we reserve the right to modify these Terms and Conditions of Use at any time. any changes will be published in our system and take effect immediately upon publication.')}<br></br>
                                    <b>6.2</b> {Locale('it is your responsibility to periodically review these terms and conditions to stay informed of any changes. Continued use of simpletool after changes are published implies your acceptance of these modifications.')}<br></br> 
                                    <h5 style={{ marginTop: 10 }}>
                                        {Locale('account termination')}
                                    </h5>
                                    <b>7.1</b> {Locale('you may terminate your account with us at any time; however, no refunds will be issued for payments made. Your access and service availability will continue until the last paid day.')}<br></br>
                                    <b>7.2</b> {Locale('we reserve the right to terminate your account or restrict your access to simpletool if you violate these terms and conditions or if suspicious or fraudulent activities are associated with your account.')}<br></br> 
                                    <h5 style={{ marginTop: 10 }}>
                                        {Locale('general provisions')}
                                    </h5>
                                    <b>8.1</b> {Locale('these terms and conditions constitute the entire agreement between you and our company regarding the use of simpletool, superseding any prior agreements.')}<br></br>
                                    <b>8.2</b> {Locale('our failure to exercise or enforce any right or provision of these terms does not constitute a waiver of such right or provision.')}<br></br> 
                                    <b>8.3</b> {Locale('if any provision of these terms and conditions is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.')}<br></br> 
                                </Text>
                                <a href='https://simpletool.com.br/politica' style={{ marginTop: 20 }} target={'_blank'}>{Locale('access our privacy policy (LGPD) for more information.')}</a>
                            </Modal>
                            <h4 style={{ cursor: 'pointer', color: 'blue' }} onClick={() => handleOpenTermsModal(true)}>
                                {Locale('has the serial? click here')}
                            </h4>
                            {
                                (window.location.href.match(/sandbox/) || window.location.href.match(/localhost/)) && (
                                    <a href='https://api.whatsapp.com/send?phone=+5511955008090&text=Solicitar+teste' target='_BLANK'>
                                        <h4 style={{ cursor: 'pointer', color: 'blue' }}>
                                            {Locale('dont registered yet? Get a test here')}
                                        </h4>
                                    </a>
                                )
                            }
                            <Button style={{ 
                                float: 'right',
                                marginTop: 15 
                            }} 
                            onClick={handleLogin}
                            shape="round" size={'large'}>
                                {Locale('login')}
                            </Button>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
    
}