import React, { useEffect, useState } from 'react'
import { 
    Typography, 
    Progress, 
    Button,
    Row,
    Col,
    Tooltip,
    Form,
    Collapse,
    Switch,
    Flex
} from 'antd'
import { 
    InfoCircleOutlined,
    CheckOutlined,
    CloseOutlined
} from '@ant-design/icons'
import { useSWRConfig } from 'swr'
import { 
    Notification
} from '../../components'
import {
    PrincipalContainer
} from '../../containers'
import { Server } from '../../modules'
import { Locale } from '../../helpers/locale'

const { Item } = Form

export default function SettingsPage ({ setCurrentPage }) {
    const { cache, mutate } = useSWRConfig()

    const [isLoading, setIsLoading] = useState(true),
        [editData, setEditData] = useState({})

    const handleSave = async () => {
        await Server.putSettings({ data: editData })

        await mutating()

        return Notification({
            title: Locale('settings updated with success')
        })
    }

    const mutating = async () => {
        await mutate('@settings', () => Server.getClientSettings({}))

        const newCache = cache.get('@settings') ?? []

        if(newCache?.length) {
            const key = newCache?.[0] || {}

            setEditData({ ...key })
        }

        setIsLoading(false)
    }

    const handleValue = (e, key, type = null, isDelete = false) => {
        const splitedKeys = key.split('.'),
            hasMoreThanOneKey = splitedKeys.length > 1

        const value = e?.target?.value

        if(hasMoreThanOneKey) {
            let tmpValue = null

            splitedKeys.forEach((key, i) => {
                if(!i) {
                    tmpValue = editData[key]
                } else if (i !== (splitedKeys.length - 1)) {
                    tmpValue = tmpValue[key]
                }
            })

            if(type && !isDelete) {
                const r = new RegExp(`array.string.\*`, 'g')

                if(type.match(r)?.length) {
                    const index = parseInt(type.split('.')[2])
                    let hasIndex = typeof tmpValue[splitedKeys[splitedKeys.length - 1]][index] == 'string'

                    if(!hasIndex) tmpValue[splitedKeys[splitedKeys.length - 1]].push(value)
                    else {
                        let newValue = hasIndex ? tmpValue[splitedKeys[splitedKeys.length - 1]][index] : ''
                        
                        newValue = !value ? '': value
    
                        tmpValue[splitedKeys[splitedKeys.length - 1]][index] = newValue
                    }
                } else if(!value || isDelete) {
                    tmpValue[splitedKeys[splitedKeys.length - 1]] = []
                } else {
                    tmpValue[splitedKeys[splitedKeys.length - 1]].push(value)
                }
            } else {
                tmpValue[splitedKeys[splitedKeys.length - 1]] = value
            }
        } else {
            editData[key] = value
        }

        setEditData({ ...editData })
    }

    const getProgressPercent = (current, max) => {
        if(current > max) return 100

        const maxDivided = 100 / max

        return current * maxDivided
    }

    const getProgressStatus = (percent) => {
        return percent >= 80 ? 'exception' : 'active'
    }

    const getStrokeColor = (progressStatus) => {
        return progressStatus == 'exception' ? '#ff4d4f' : '#52c41a'
    }

    const mountCollapses = () => {
        const panels = [
            {
                title: Locale('client configurations'),
                titleDesc: '',
                tutorialDesc: '',
                children: () => {
                    return (
                        <>
                            <Form 
                                layout={'vertical'}
                            >
                                <Row 
                                    gutter={{
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                    }}
                                >
                                    <Col className="gutter-row" span={24}>
                                        <Item label={Locale('open pull request on every change of apis and workers (dev mode)')}>
                                            <Switch 
                                                onChange={(value) => handleValue({ target: { value } }, 'open_pull_request')}
                                                checkedChildren={<CheckOutlined />}
                                                unCheckedChildren={<CloseOutlined />}
                                                checked={Boolean(editData?.open_pull_request)}
                                                style={{ float: 'left' }}
                                            />
                                        </Item>
                                    </Col>
                                    {/* <Col className="gutter-row" span={24}>
                                        <Item label={Locale('open pull request on every change of apis and workers')}>
                                            <Switch 
                                                onChange={(value) => handleValue({ target: { value } }, 'open_pull_request')}
                                                checkedChildren={<CheckOutlined />}
                                                unCheckedChildren={<CloseOutlined />}
                                                checked={editData?.open_pull_request}
                                                style={{ float: 'left' }}
                                            />
                                        </Item>
                                    </Col> */}
                                </Row>
                            </Form>
                        </>
                    )
                }
            },
            {
                title: Locale('plan') + ` (${editData.name})`,
                titleDesc: '',
                tutorialDesc: <h2>Métricas de uso</h2>,
                children: () => {
                    return <>
                        <Flex gap="small" vertical>
                            {Locale('connections')} {`(${editData.current_connections_count}/${editData.max_connections})`}: <Progress percent={getProgressPercent(editData.current_connections_count, editData.max_connections)}
                                status={getProgressStatus(getProgressPercent(editData.current_connections_count, editData.max_connections))} 
                                strokeColor={getStrokeColor(getProgressStatus(getProgressPercent(editData.current_connections_count, editData.max_connections)))} />
                            Apis {`(${editData.current_apis_count}/${editData.max_apis})`}: <Progress 
                                percent={getProgressPercent(editData.current_apis_count, editData.max_apis)}
                                status={getProgressStatus(getProgressPercent(editData.current_apis_count, editData.max_apis))} 
                                strokeColor={getStrokeColor(getProgressStatus(getProgressPercent(editData.current_apis_count, editData.max_apis)))} />
                            Workers {`(${editData.current_workers_count}/${editData.max_workers})`}: <Progress percent={getProgressPercent(editData.current_workers_count, editData.max_workers)}
                                status={getProgressStatus(getProgressPercent(editData.current_workers_count, editData.max_workers))} 
                                strokeColor={getStrokeColor(getProgressStatus(getProgressPercent(editData.current_workers_count, editData.max_workers)))} />
                            {Locale('users')} {`(${editData.current_users_count}/${editData.max_users})`}: <Progress percent={getProgressPercent(editData.current_users_count, editData.max_users)}
                                status={getProgressStatus(getProgressPercent(editData.current_users_count, editData.max_users))} 
                                strokeColor={getStrokeColor(getProgressStatus(getProgressPercent(editData.current_users_count, editData.max_users)))} showInfo={true} />
                            AI* {`(${editData.current_ai_calls}/${editData.max_ai_calls})`}: <Progress percent={getProgressPercent(editData.current_ai_calls, editData.max_ai_calls)}
                                status={getProgressStatus(getProgressPercent(editData.current_ai_calls, editData.max_ai_calls))} 
                                strokeColor={getStrokeColor(getProgressStatus(getProgressPercent(editData.current_ai_calls, editData.max_ai_calls)))} />
                            {Locale('* renewed each first day of month at 00:00')}
                        </Flex>
                    </>
                }
            }
        ]

        return panels.map(panel => <Collapse.Panel header={
            <>
                <Typography.Title level={5} style={{ textAlign: 'left', marginTop: 3 }}>
                    {panel.title}
                    {panel.titleDesc?.length > 0 && (
                        <Tooltip placement="topLeft" title={panel.titleDesc}>
                            <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                        </Tooltip>    
                    )}
                </Typography.Title>
            </>
        }>
            {panel.tutorialDesc}
            {panel.children()}
        </Collapse.Panel>)
    }

    useEffect(() => {
        const settings = cache.get('@settings') || {
            open_pull_request: true
        }

        setEditData({ ...settings })

        console.log(editData)

        setIsLoading(false)
    }, [isLoading])

    return (
        <>
            <PrincipalContainer menuItemSelected='23' setCurrentPage={setCurrentPage} content={
                <div>
                    <Typography.Title level={2} style={{ textAlign: 'left' }}>
                        {Locale('settings')} 
                    </Typography.Title>
                    <Row>
                        <Col span={24}>
                            <Collapse
                                accordion={true}
                                bordered={false}
                                // activeKey={[0, 1]}
                            >
                                {mountCollapses()}
                            </Collapse> 
                            <Button style={{ float: 'right', top: 5 }} type="primary" onClick={handleSave}>{Locale('save')}</Button>
                        </Col>
                    </Row>
                </div>
            } />
        </>
    )
}