import React, { useState, useEffect } from "react"
import { 
    Tag,
    Button,
    Divider,
    Switch,
    Tooltip,
    Select,
    Input,
    Alert,
    Space,
    Form, 
    Tabs,
    Collapse,
    Typography
} from 'antd'
import {
    CheckOutlined,
    CloseOutlined,
    InfoCircleOutlined,
    ThunderboltOutlined,
    CoffeeOutlined
} from '@ant-design/icons'
import Editor from '@monaco-editor/react'
import { Notification } from "../.."
import { Locale } from "../../../helpers/locale"

const { Item } = Form

export default function TabList ({ handleValue = () => {}, values = {}, handleAI = () => {}, isEdit = false, appId = null, endpointName = null, endpointName2 = null }) {
    const [requestParams, setRequestParams] = useState(values?.request),
        [responseParams, setResponseParams] = useState(values?.response?.schema),
        [endpointDisabled, setEndpointDisabled] = useState(false),
        [rawQuery, setRawQuery] = useState(false),
        [pagination, setPagination] = useState(values?.response?.has_total_count),
        [responsePreview, setResponsePreview] = useState(false),
        [responseParamInputValue, setResponseParamInputValue] = useState(''),
        [saveButtonDisabled, setSaveButtonDisabled] = useState(true),
        [aiButtonDisabled, setAIButtonDisabled] = useState(true),
        [editorCodePrd, setEditorCodePrd] = useState(''),
        [editorCodeHml, setEditorCodeHml] = useState(''),
        [editorCodeSdb, setEditorCodeSdb] = useState(''),
        [editorCodeDev, setEditorCodeDev] = useState(''),
        [mergeValues, setMergeValues] = useState([])

    const orderByTypes = [
        'ASC',
        'DESC'
    ]

    const handleMergeValues = (index, indexMerge, value) => {
        let hasIndex = typeof mergeValues[index] == 'string'

        if(!hasIndex) mergeValues[index][indexMerge] = value
        else {
            mergeValues[index][indexMerge] = value
        }

        setMergeValues([ ...mergeValues ])
    }

    const handleSaveQuerySchema = () => {
        const mergeValue1 = mergeValues?.[0]?.join('|') || [],
            mergeValue2 = mergeValues?.[1]?.join('|') || []

        if(mergeValue1?.length > 0) {
            if(!mergeValues?.[0]?.[1]?.match('as ')?.length) {
                return Notification({
                    title: 'Salvar Queries',
                    description: "A chave da query 'data.1' necessita ser igual a 'exemplo as chave' ou 'as chave'",
                    type: 'warning'
                })
            }

            if(!mergeValues?.[0]?.[2]?.match('=')?.length || (!mergeValues?.[0]?.[2]?.split('=')?.[0]?.length || !mergeValues?.[0]?.[2]?.split('=')?.[1]?.length)) {
                return Notification({
                    title: 'Salvar Queries',
                    description: "O compare da query 'data.1' necessita ser igual a 'exemplo_id=id'",
                    type: 'warning'
                })
            }

            handleValue({ target: { value: mergeValue1  } }, 'request.list.merge_values', 'array.string.0')
        }

        if(mergeValue2?.length > 0) {
            if(!mergeValues?.[1]?.[1]?.match(' as ')?.length) {
                return Notification({
                    title: 'Salvar Queries',
                    description: "A chave da query 'data.2' necessita ser igual a 'exemplo as chave'",
                    type: 'warning'
                })
            }

            if(!mergeValues?.[1]?.[2]?.match('=')?.length || (!mergeValues?.[1]?.[2]?.split('=')?.[0]?.length || !mergeValues?.[1]?.[2]?.split('=')?.[1]?.length)) {
                return Notification({
                    title: 'Salvar Queries',
                    description: "O compare da query 'data.2' necessita ser igual a 'exemplo_id=id'",
                    type: 'warning'
                })
            }

            handleValue({ target: { value: mergeValue2  } }, 'request.list.merge_values', 'array.string.1')
        }
    }

    const handleShowResponsePreview = () => {
        const newResponsePreview = !responsePreview ? true : false
        setResponsePreview(newResponsePreview)
    }

    const showResponsePreview = () => {
        const obj = {}

        responseParams.forEach((param, i) => {
            Object.assign(obj, { [param]: `preview_${param}` })
        })

        const responseObj = { response_data: !Object.keys(obj)?.length && [] || [obj, obj] }

        if(pagination) {
            Object.assign(responseObj, { 
                pagination: {
                    total: 100,
                    next_offset: 10, 
                    pages: 10,
                    page: 1, 
                    is_last_page: false
                }
            })
        }

        return JSON.stringify(responseObj, null, 2)
    }

    const handleEnableEndpoint = () => {
        const newValue = !endpointDisabled ? true : false
        setEndpointDisabled(newValue)
        handleValue({ target: { value: Number(newValue) } }, 'request.list.status')
    }

    const handleEnablePagination = () => {
        const newValue = !pagination ? true : false

        setPagination(newValue)

        handleValue({ target: { value: newValue } }, 'response.list.has_total_count')
    }

    const handleEnableRawQuery = () => {
        const newValue = !rawQuery ? true : false

        setRawQuery(newValue)

        if(!newValue) {
            handleValue({ target: { value: [] } }, 'request.list.raw_query')
        } else {
            handleValue({ target: { value: null } }, 'request.list.order_by')
            handleValue({ target: { value: null } }, 'request.list.order_by_type')
        }
    }

    const handleRemoveParam = (value, type) => {
        switch (type) {
            case 'request':
                const newRequestParams = requestParams

                setRequestParams(newRequestParams)
                break
            case 'response':
                handleValue({ target: { value } }, 'response.list.schema', 'array', true)
                break
        }
    }

    useEffect(() => {
        if(values?.request?.raw_query?.length) {
            setRawQuery(true)
        } else {
            setRawQuery(false)
        }

        setEditorCodePrd('')
        setEditorCodeHml('')
        setEditorCodeSdb('')
        setEditorCodeDev('')
    }, [appId])

    useEffect(() => {
        setResponseParams(values?.response?.schema)
        setRequestParams(values?.request)

        if(values?.request?.merge_values?.length > 0) {
            const mergeValue0Split = values?.request?.merge_values?.[0].split('|') || [],
                mergeValues1Split = values?.request?.merge_values?.[1].split('|') || []

            let mergeValues = []

            if(mergeValue0Split?.length) {
                mergeValues.push([mergeValue0Split[0], mergeValue0Split[1], mergeValue0Split[2], mergeValue0Split[3]])
            }

            if(mergeValues1Split?.length) {
                mergeValues.push([mergeValues1Split[0], mergeValues1Split[1], mergeValues1Split[2], mergeValues1Split[3]])
            }
            
            setMergeValues([ ...mergeValues ])
        } else {
            setMergeValues([
                ['data'],
                []
            ])
        }  

        if(values?.response?.has_total_count) {
            setPagination(Boolean(values?.response?.has_total_count))
        } else {
            setPagination(false)
        }

        setEndpointDisabled(values?.request?.status == 0 ? 0 : 1)
    }, [values, appId])

    useEffect(() => {
        if((values?.request?.dev_mode?.prd.code[0])) {
            const codePrd = values?.request?.dev_mode?.prd.code
            if((editorCodePrd != codePrd)) setEditorCodePrd(codePrd)
        }

        if((values?.request?.dev_mode?.hml.code[0])) {
            const codeHml = values?.request?.dev_mode?.hml.code
            if((editorCodeHml != codeHml)) setEditorCodeHml(codeHml)
        }

        if((values?.request?.dev_mode?.sdb.code[0])) {
            const codeSdb = values?.request?.dev_mode?.sdb.code
            if((editorCodeSdb != codeSdb)) setEditorCodeSdb(codeSdb)
        }

        if(values?.request?.dev_mode?.dev.code[0]) {
            const codeDev = values?.request?.dev_mode?.dev.code
            if((editorCodeDev != codeDev)) setEditorCodeDev(codeDev)
        } 
    }, [requestParams])

    const handleSaveCode = () => {
        const codePrd = values?.request?.dev_mode?.prd.code
        if((editorCodePrd != codePrd)) handleValue(editorCodePrd, 'request.list.dev_mode.prd.code')
        else handleValue(codePrd, 'request.list.dev_mode.prd.code')

        const codeHml = values?.request?.dev_mode?.hml.code
        if((editorCodeHml != codeHml)) handleValue(editorCodeHml, 'request.list.dev_mode.hml.code')
        else handleValue(codeHml, 'request.list.dev_mode.hml.code')

        const codeSdb = values?.request?.dev_mode?.sdb.code
        if((editorCodeSdb != codeSdb)) handleValue(editorCodeSdb, 'request.list.dev_mode.sdb.code')
        else handleValue(codeSdb, 'request.list.dev_mode.sdb.code')

        const codeDev = values?.request?.dev_mode?.dev.code
        if((editorCodeDev != codeDev)) handleValue(editorCodeDev, 'request.list.dev_mode.dev.code')
        else handleValue(codeDev, 'request.list.dev_mode.dev.code')

        setSaveButtonDisabled(true)
        setAIButtonDisabled(false)

        if(!(editorCodePrd?.length 
            || editorCodeHml?.length 
            || editorCodeSdb?.length 
            || editorCodeDev?.length)) 
            setAIButtonDisabled(true)
    }

    const tabsEnv = [
        <>
            <h3 style={{ textAlign: 'left' }}>
                {Locale('production')} <Button 
                    style={{ float: 'right' }}
                    onClick={() => handleAI(editorCodePrd)}
                    disabled={aiButtonDisabled}    
                    color={'pink'}
                    variant={'solid'}
                >{Locale('analyze with simple ai')}</Button>
            </h3>
            <Editor
                language='javascript'
                value={editorCodePrd || ''}
                theme='vs-dark'
                height="75vh" 
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodePrd(newValue)
                }}
                options={{
                    autoIndent: "full"
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                {Locale('homolog')} <Button 
                    style={{ float: 'right' }}
                    onClick={() => handleAI(editorCodeHml)}
                    disabled={aiButtonDisabled}    
                    color={'pink'}
                    variant={'solid'}
                >{Locale('analyze with simple ai')}</Button>
            </h3>
            <Editor
                language='javascript'
                value={editorCodeHml || ''}
                theme='vs-dark'
                height="75vh" 
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodeHml(newValue)
                }}
                options={{
                    autoIndent: "full"
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                Sandbox <Button 
                    style={{ float: 'right' }}
                    onClick={() => handleAI(editorCodeSdb)}
                    disabled={aiButtonDisabled}    
                    color={'pink'}
                    variant={'solid'}
                >{Locale('analyze with simple ai')}</Button>
            </h3>
            <Editor
                language='javascript'
                value={editorCodeSdb || ''}
                theme='vs-dark'
                height="75vh" 
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodeSdb(newValue)
                }}
                options={{
                    autoIndent: "full"
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                {Locale('dev')} <Button 
                    style={{ float: 'right' }}
                    onClick={() => handleAI(editorCodeDev)}
                    disabled={aiButtonDisabled}    
                    color={'pink'}
                    variant={'solid'}
                >{Locale('analyze with simple ai')}</Button>
            </h3>
            <Editor
                language='javascript'
                value={editorCodeDev || ''}
                theme='vs-dark'
                height="75vh" 
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodeDev(newValue)
                }}
                options={{
                    autoIndent: "full"
                }}
            />
        </>
    ]

    const tabs = [
        <>
            <Collapse
                accordion={true}
                bordered={false}
            >
                <Collapse.Panel header={
                    <>
                        <Typography.Title level={5} style={{ textAlign: 'left', marginTop: 3 }}>
                            {Locale('request')}
                            <Tooltip placement="topLeft" title={Locale('request configurations')}>
                                <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                            </Tooltip>    
                        </Typography.Title>
                    </>
                }>
                    <Item label={(<>
                        {'RAW Query'}
                        <Tooltip placement="topLeft" title={Locale('you can create a RAW Query and pass the values with expression {variable_name}. If have authentication the system will try to fill in the variable and search the same name inside the session has been created with Reverse Authorization and if the variable is not finded the system will try to search in Query String. You don\'t need a RAW Query to utilize Query String.')}>
                            <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                        </Tooltip>
                        <Tooltip placement="topLeft" title={Locale('Raw query actived/deactived')}>
                            <Switch 
                                onChange={handleEnableRawQuery}
                                style={{ float: 'right', marginLeft: 10 }}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={rawQuery}
                            />
                        </Tooltip>
                        </>)}
                    >
                        {
                            rawQuery && (
                                <>
                                    <Input.TextArea 
                                        placeholder={'SELECT * FROM {table} WHERE id = {id} AND user_id = {user_id} LIMIT {limit} OFFSET {offset}'}
                                        value={requestParams?.raw_query?.[0]}
                                        onChange={(e) => handleValue(e, 'request.list.raw_query', 'array.string.0')}
                                    />
                                    {/* <Button style={{ float: 'right', marginTop: 10 }} type="default" shape="round" onClick={() => handleSaveQuerySchema()}>Salvar Query</Button> */}
                                    <Typography.Paragraph style={{ marginTop: 10, cursor: 'pointer' }}>Default vars: {'{table}, {limit}, {offset}'}</Typography.Paragraph>
                                </>
                            )
                        }
                    </Item>
                    {
                        !rawQuery && (
                            <>
                                <Item label={(<>
                                    {'Order by'}
                                    <Tooltip placement="topLeft" title={Locale('define the table field name to order')}>
                                        <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                                    </Tooltip>
                                    </>)}
                                >
                                    <Input 
                                        placeholder={Locale('the default value is id')}
                                        value={requestParams?.order_by}
                                        onChange={(e) => handleValue(e, 'request.list.order_by')}
                                    />
                                </Item>
                                <Item label={(<>
                                    {Locale('type of order by')}
                                    <Tooltip placement="topLeft" title={Locale('define the order type')}>
                                        <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                                    </Tooltip>
                                    </>)}
                                >
                                    <Select
                                        style={{
                                            width: '100%',
                                        }}
                                        
                                        options={orderByTypes?.map(type => {
                                            return {
                                                label: type,
                                                value: type.toUpperCase()
                                            } 
                                        })} 
                                        onChange={(e) => handleValue({ target: { value: e } }, 'request.list.order_by_type')}
                                        onInputKeyDown={(e) => e.preventDefault()}
                                        value={values?.request?.order_by_type}
                                        defaultValue={isEdit && requestParams?.order_by_type || 'DESC'}
                                    />
                                </Item>
                            </>
                        )
                    }
                </Collapse.Panel>
                <Collapse.Panel header={
                    <>
                        <Typography.Title level={5} style={{ textAlign: 'left', marginTop: 3 }}>
                            {Locale('response')}
                            <Tooltip placement="topLeft" title={Locale('configurations of request response')}>
                                <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                            </Tooltip>    
                        </Typography.Title>
                    </>
                }>
                    <div style={{ marginBottom: 10 }}>
                        {Locale('pagination')} <Tooltip placement="topLeft" title={Locale('pagination actived/deactived')}>
                            <Switch 
                                onChange={handleEnablePagination}
                                style={{ marginLeft: 10 }}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={pagination}
                            />
                        </Tooltip>
                    </div>
                    <Input 
                        style={{ 
                            marginBottom: 20
                        }}
                        placeholder={Locale('type the paramater exactly it will returned from DB and press ENTER')}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleValue(e, 'response.list.schema', 'array')
                                setResponseParamInputValue('')
                            }
                        }}
                        value={responseParamInputValue}
                        onChange={(e) => setResponseParamInputValue(e.target.value)}
                    />
                    <Select
                        mode="tags"
                        style={{
                            width: '83%',
                        }}
                        allowClear
                        bordered={false}
                        showArrow={false}
                        options={responseParams?.map(param => {
                            return {
                                label: param,
                                value: param
                            }
                        })} 
                        onClear={(e) => handleRemoveParam(e, 'response')}
                        onChange={(e) => handleRemoveParam(e, 'response')}
                        dropdownStyle={{ display: 'none' }} 
                        onInputKeyDown={(e) => e.preventDefault()}
                        value={responseParams}
                    />
                        <Button style={{ float: 'right' }} type="default" shape="round" onClick={() => handleShowResponsePreview()}>{Locale('see response')}</Button>
                    {responsePreview && (
                        <Space
                            direction="vertical"
                            style={{
                                width: '100%',
                                marginTop: 10
                            }}
                        >
                            <Alert 
                                closable={true}
                                onClose={() => handleShowResponsePreview()}
                                message={Locale('response preview')}
                                description={<pre>{showResponsePreview()}</pre>}
                                type="success"
                            />
                        </Space>
                    )}
                </Collapse.Panel>
            </Collapse>
            {/* <Divider orientation="left">
                Requisição
                <Tooltip placement="topLeft" title="Configurações da requisição">
                    <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                </Tooltip>    
            </Divider> */}
            
            {/* <div id={'request-params'}>
                <Item label={(<>
                    {'RAW Query'}
                    <Tooltip placement="topLeft" title={`
                        Você pode criar uma RAW Query e passar valores com a expressão {nome_variavel}... caso tenha autenticação, o sistema irá tentar preencher a variável
                        buscando O MESMO NOME dentro da sessão criada através do REVERSE AUTH, caso não encontre, buscará na QUERY STRING da URL.
                        É permitido sempre utilizar QUERY STRING independente de uma RAW Query
                        `}>
                        <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                    </Tooltip>
                    <Tooltip placement="topLeft" title="Raw query ativo/desativado">
                        <Switch 
                            onChange={handleEnableRawQuery}
                            style={{ float: 'right', marginLeft: 10 }}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={rawQuery}
                        />
                    </Tooltip>
                    </>)}
                >
                    {
                        rawQuery && (
                            <>
                                <Input
                                    addonBefore={'data'}
                                    type={'text'}
                                    placeholder={'SELECT * FROM {table} WHERE id = {id} AND user_id = {user_id} LIMIT {limit} OFFSET {offset}'}
                                    value={requestParams?.raw_query?.[0]}
                                    onChange={(e) => handleValue(e, 'request.list.raw_query', 'array.string.0')}
                                /> */}
                                {/* <Divider />
                                <Input
                                    style={{ marginTop: 10 }}
                                    addonBefore={'data.1'}
                                    type={'text'}
                                    placeholder={'SELECT * FROM {table} WHERE id = {id} AND user_id = {user_id} LIMIT {limit} OFFSET {offset}'}
                                    value={requestParams?.raw_query?.[1]}
                                    onChange={(e) => handleValue(e, 'request.list.raw_query', 'array.string.1') 
                                    }
                                />

                                <Row style={{ marginTop: 10 }}>
                                    <Col span={7}>
                                        <Tooltip placement="topLeft" title="Chave que irá dentro do objeto de 'data', exemplo: data as order_products">
                                            <Input 
                                                addonBefore={'Chave'}
                                                type={'text'}
                                                placeholder={'chave do objeto'}
                                                value={mergeValues?.[0]?.[1] || ''}
                                                onChange={(e) => {
                                                    const value = e.target.value

                                                    handleMergeValues(0, 1, value)
                                                }}
                                            />
                                        </Tooltip>
                                    </Col>
                                    <Col span={3}>
                                        <Tooltip placement="topLeft" title="Dados que iremos usar, selecionado pela query">
                                            <Select
                                                style={{
                                                    width: '100%',
                                                }}
                                                options={['data']?.map(val => {
                                                    return {
                                                        label: val,
                                                        value: val
                                                    } 
                                                })} 
                                                onChange={(e) => {
                                                    const value = e

                                                    handleMergeValues(0, 0, value)
                                                }}
                                                onInputKeyDown={(e) => e.preventDefault()}
                                                value={'data'}
                                                defaultValue={'data'}
                                                disabled
                                            />
                                        </Tooltip>
                                    </Col>
                                    <Col span={8}>
                                        <Tooltip placement="topLeft" title="Valor de comparação entre os dados de 'data.1' e 'data', exemplo: id=order_id">
                                            <Input 
                                                addonBefore={'Compare'}
                                                type={'text'}
                                                placeholder={'exemplo: product_id=id'}
                                                value={mergeValues?.[0]?.[2] || ''}
                                                onChange={(e) => {
                                                    const value = e.target.value.trim()

                                                    handleMergeValues(0, 2, value)
                                                }}
                                            />
                                        </Tooltip>
                                    </Col>
                                    <Col span={6}>
                                        <Tooltip placement="topLeft" title="tipo da chave: nenhum = não retorna a chave indicada, array = chave retorna em forma de lista">
                                            <Select
                                                style={{
                                                    width: '100%',
                                                }}
                                                options={['false', 'array']?.map(val => {
                                                    const v = val == 'false' ? 'nenhum' : val
                                                    return {
                                                        label: v,
                                                        value: val
                                                    } 
                                                })} 
                                                onChange={(e) => {
                                                    const value = e

                                                    handleMergeValues(0, 3, value)
                                                }}
                                                onInputKeyDown={(e) => e.preventDefault()}
                                                value={mergeValues?.[0]?.[3]}
                                                defaultValue={'false'}
                                            />
                                        </Tooltip>
                                    </Col>
                                </Row>

                                <Divider />
                                
                                <Input
                                    style={{ marginTop: 10 }}
                                    addonBefore={'data.2'}
                                    type={'text'}
                                    placeholder={'SELECT * FROM {table} WHERE id = {id} AND user_id = {user_id} LIMIT {limit} OFFSET {offset}'}
                                    value={requestParams?.raw_query?.[2]}
                                    onChange={(e) => handleValue(e, 'request.list.raw_query', 'array.string.2')}
                                />

                                <Row style={{ marginTop: 10, marginBottom: 10 }}>
                                    <Col span={7}>
                                        <Tooltip placement="topLeft" title="Chave que irá dentro do objeto de 'data', exemplo: data as order_products">
                                            <Input 
                                                addonBefore={'Chave'}
                                                type={'text'}
                                                placeholder={'chave do objeto'}
                                                value={mergeValues?.[1]?.[1] || ''}
                                                onChange={(e) => {
                                                    const value = e.target.value

                                                    handleMergeValues(1, 1, value)
                                                }}
                                            />
                                        </Tooltip>
                                    </Col>
                                    <Col span={3}>
                                        <Tooltip placement="topLeft" title="Dados que iremos usar, selecionado pela query">
                                            <Select
                                                style={{
                                                    width: '100%',
                                                }}
                                                options={['data', 'data.1']?.map(val => {
                                                    return {
                                                        label: val,
                                                        value: val
                                                    } 
                                                })} 
                                                onChange={(e) => {
                                                    const value = e

                                                    handleMergeValues(1, 0, value)
                                                }}
                                                onInputKeyDown={(e) => e.preventDefault()}
                                                value={mergeValues?.[1]?.[0] || ''}
                                                defaultValue={mergeValues?.[1]?.[0] || ''}
                                            />
                                        </Tooltip>
                                    </Col>
                                    <Col span={8}>
                                        <Tooltip placement="topLeft" title="Valor de comparação entre os dados de 'data.1' e 'data', exemplo: id=order_id">
                                            <Input 
                                                addonBefore={'Compare'}
                                                type={'text'}
                                                placeholder={'exemplo: product_id=id'}
                                                value={mergeValues?.[1]?.[2] || ''}
                                                onChange={(e) => {
                                                    const value = e.target.value.trim()

                                                    handleMergeValues(1, 2, value)
                                                }}
                                            />
                                        </Tooltip>
                                    </Col>
                                    <Col span={6}>
                                        <Tooltip placement="topLeft" title="tipo da chave: nenhum = não retorna a chave indicada, array = chave retorna em forma de lista">
                                            <Select
                                                style={{
                                                    width: '100%',
                                                }}
                                                options={['false', 'array']?.map(val => {
                                                    const v = val == 'false' ? 'nenhum' : val
                                                    return {
                                                        label: v,
                                                        value: val
                                                    } 
                                                })} 
                                                onChange={(e) => {
                                                    const value = e

                                                    handleMergeValues(1, 3, value)
                                                }}
                                                onInputKeyDown={(e) => e.preventDefault()}
                                                value={mergeValues?.[1]?.[3]}
                                                defaultValue={'false'}
                                            />
                                        </Tooltip>
                                    </Col>
                                </Row> */}
                                {/* <Button style={{ float: 'right', marginTop: 10 }} type="primary" shape="round" onClick={() => handleSaveQuerySchema()}>Salvar Query</Button>
                            </>
                        )
                    } */}
                {/* </Item>
                {
                    !rawQuery && (
                        <>
                            <Item label={(<>
                                {'Order by'}
                                <Tooltip placement="topLeft" title={`
                                    Defina o nome do campo para realizar a ordenação
                                    `}>
                                    <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                                </Tooltip>
                                </>)}
                            >
                                <Input 
                                    placeholder={'O valor padrão é id'}
                                    value={requestParams?.order_by}
                                    onChange={(e) => handleValue(e, 'request.list.order_by')}
                                />
                            </Item>
                            <Item label={(<>
                                {'Order by tipo'}
                                <Tooltip placement="topLeft" title={`
                                    Defina o tipo de ordenação
                                    `}>
                                    <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                                </Tooltip>
                                </>)}
                            >
                                <Select
                                    style={{
                                        width: '100%',
                                    }}
                                    
                                    options={orderByTypes?.map(type => {
                                        return {
                                            label: type,
                                            value: type.toUpperCase()
                                        } 
                                    })} 
                                    onChange={(e) => handleValue({ target: { value: e } }, 'request.list.order_by_type')}
                                    onInputKeyDown={(e) => e.preventDefault()}
                                    value={values?.request?.order_by_type}
                                    defaultValue={isEdit && requestParams?.order_by_type || 'DESC'}
                                />
                            </Item>
                        </>
                    )
                } */}
            {/* </div> */}

            {/* <Divider orientation="left">
                Resposta 
                <Tooltip placement="topLeft" title="Configurações da resposta da requisição">
                    <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                </Tooltip>
            </Divider> */}
            {/* <div id={'response-params'}>
                <div style={{ marginBottom: 10 }}>
                    Paginação <Tooltip placement="topLeft" title="Paginação ativo/desativado">
                        <Switch 
                            onChange={handleEnablePagination}
                            style={{ marginLeft: 10 }}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={pagination}
                        />
                    </Tooltip>
                </div>
                <Input 
                    style={{ 
                        marginBottom: 20
                    }}
                    placeholder={'Digite o parâmetro exatamente como irá retornar do banco de dados e dê enter'}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleValue(e, 'response.list.schema', 'array')
                            setResponseParamInputValue('')
                        }
                    }}
                    value={responseParamInputValue}
                    onChange={(e) => setResponseParamInputValue(e.target.value)}
                />
                <Select
                    mode="tags"
                    style={{
                        width: '83%',
                    }}
                    allowClear
                    bordered={false}
                    showArrow={false}
                    options={responseParams?.map(param => {
                        return {
                            label: param,
                            value: param
                        }
                    })} 
                    onClear={(e) => handleRemoveParam(e, 'response')}
                    onChange={(e) => handleRemoveParam(e, 'response')}
                    dropdownStyle={{ display: 'none' }} 
                    onInputKeyDown={(e) => e.preventDefault()}
                    value={responseParams}
                />
                    <Button style={{ float: 'right' }} type="primary" shape="round" onClick={() => handleShowResponsePreview()}>Ver resposta</Button>
                {responsePreview && (
                    <Space
                        direction="vertical"
                        style={{
                            width: '100%',
                            marginTop: 10
                        }}
                    >
                        <Alert 
                            closable={true}
                            onClose={() => handleShowResponsePreview()}
                            message="Preview de resposta"
                            description={<pre>{showResponsePreview()}</pre>}
                            type="success"
                        />
                    </Space>
                )}
            </div> */}
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                Dev Mode
            </h3>
            <h5 style={{ textAlign: 'left' }}>
                {/* Faça o clone do nosso <a href="https://github.com/codetec-repos/simplerest-dev-boilerplate">boilerplate</a> para auxiliar no desenvolvimento <br></br> */}
                {Locale('if has code in "Dev Mode" the "Simple Mode" is ignored')}<br></br>
                {Locale('The code always end with a return {object} or return {array} to fill the response')}<br></br>
                {Locale('after change, click in "save code" to confirm and after save a Pull Request will be open')}<br></br>
                {Locale('* in Sanbox use only the environment "Dev" for test')}
            </h5>
            <Input placeholder={Locale('type the Pull Request description')} value={requestParams?.description} onChange={(e) => handleValue(e, 'request.list.description')} />
            <Typography.Paragraph style={{ marginTop: 10, cursor: 'pointer' }}>Default vars: 
                <Tooltip placement="topLeft" title={Locale('process NodeJS variable')}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>process</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={Locale('require NodeJS variable')}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>require</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={Locale('Knex.js instance')}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>db</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={<pre>{`
const {
    queryString,
    session,
    simpleApiCallService: {
        call: async (apiId : String, method : String('list' | 'get' | 'post' | 'put' | 'delete'), data = {} : Object, params = {} : Object) => {}
    }
} = simplerest
                    `}</pre>}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>simplerest</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={<pre>{`
const {
    S3Service,
    SimplePayments,
    PDFGenerator,
    CSVGenerator,
    DateFormat,
    SimpleNotifications
} = simplelib
                `}</pre>}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>simplelib</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={<pre>{`
const {
    email: {
        config: {
            host: "",
            port: "",
            secure: false
        },
        auth: { 
            user: "",
            pass: ""
        },
        emailParams: {
            from: ""
        }
    },
    slack: {
        auth: {
            oauthToken: ""
        }
    },
    zenvia: {
        auth: {
            token: ""
        }
    },
    stripe: {
        auth: {
            secretKey: ""
        }
    },
    mercadoPago: {
        auth: {
            accessToken: "",
            webhook: ""
        }
    },
    aws: {
        auth: {
            region: "",
            accessKey: "",
            secretKey: ""
        }
    }
} = simplekeys
                `}</pre>}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>simplekeys</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={<pre>{`
const {
    list: async (pattern = '*' : String) => {},
    get: async (key : String) => {},
    set: async (key : String, value : Object, expires : Integer) => {},
    del: async (key : String) => {}
} = cache
                `}</pre>}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>cache</Typography.Text>
                </Tooltip>
                <Tabs
                    defaultActiveKey="2"
                    items={[Locale('production'), Locale('homolog'), 'Sandbox', Locale('dev')].map((tabName, i) => {
                        return {
                            label: (
                            <span>
                                {tabName}
                            </span>
                            ),
                            key: i + 1,
                            children: tabsEnv[i],
                        }
                    })}
                />
            </Typography.Paragraph>
            <Button style={{ marginTop: 10, float: 'right' }} disabled={saveButtonDisabled} type="default" shape="round" onClick={handleSaveCode}>{Locale('save code')}</Button>
        </>
    ]

    return (
        <div>
            <h3 style={{ textAlign: 'left' }}>
                <Tag color="success">LIST | GET</Tag> {isEdit ? (endpointName2?.length > 0 ? `/v1/${appId}${endpointName?.length > 0 ? `|${endpointName}` : ''}/${endpointName2}/{limit}/{offset}` : `/v1/${appId}${endpointName?.length > 0 ? `|${endpointName}` : ''}/{limit}/{offset}`) : `/v1/{app_id}|{endpoint_name}/?{endpoint_name2}/{limit}/{offset}`}
                <br></br>{Locale('or')}<br></br>
                {isEdit && endpointName2?.length > 0 ? (endpointName2?.length > 0 ? `/v1/${appId}${endpointName?.length > 0 ? `|${endpointName}` : ''}/{id}/${endpointName2}/{limit}/{offset}` : `/v1/${appId}${endpointName?.length > 0 ? `|${endpointName}` : ''}/{limit}/{offset}`) : `/v1/{app_id}|{endpoint_name}/{id}/?{endpoint_name2}/{limit}/{offset}`}
                <Tooltip placement="topLeft" title={Locale('endpoint actived/deactived')}>
                    <Switch 
                        onChange={handleEnableEndpoint}
                        style={{ float: 'right' }}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={endpointDisabled}
                    />
                </Tooltip>
            </h3>

            <Tabs
                defaultActiveKey="2"
                items={[ThunderboltOutlined, CoffeeOutlined].map((Icon, i) => {
                    const tabName = i == 0 ? 'Simple Mode' : 'Dev Mode' 
                    return {
                        label: (
                        <span>
                            <Icon />
                            {tabName}
                        </span>
                        ),
                        key: i + 1,
                        children: tabs[i],
                    }
                })}
            />
            
        </div>
    )
} 