import React, { useState, useEffect } from "react"
import { 
    Tag,
    Button,
    Divider,
    Switch,
    Tooltip,
    Input,
    Form,
    Tabs,
    Select,
    Row,
    Col,
    Typography
} from 'antd'
import {
    CheckOutlined,
    CloseOutlined,
    InfoCircleOutlined,
    ThunderboltOutlined,
    CoffeeOutlined
} from '@ant-design/icons'
import Editor from '@monaco-editor/react'
import { Locale } from '../../../helpers/locale'

const { Item } = Form

export default function TabDelete ({ handleValue = () => {}, values = {}, handleAI = () => {}, isEdit = false, appId = null, endpointName = null, endpointName2 = null, workers = [] }) {
    const [endpointDisabled, setEndpointDisabled] = useState(values?.request?.status || false),
        [rawQuery, setRawQuery] = useState(false),
        [saveButtonDisabled, setSaveButtonDisabled] = useState(true),
        [aiButtonDisabled, setAIButtonDisabled] = useState(true),
        [editorCodePrd, setEditorCodePrd] = useState(''),
        [editorCodeHml, setEditorCodeHml] = useState(''),
        [editorCodeSdb, setEditorCodeSdb] = useState(''),
        [editorCodeDev, setEditorCodeDev] = useState('')


    const handleEnableEndpoint = () => {
        const newValue = !endpointDisabled ? true : false
        setEndpointDisabled(newValue)
        handleValue({ target: { value: Number(newValue) } }, 'request.delete.status')
    }

    const handleEnableRawQuery = () => {
        const newValue = !rawQuery ? true : false

        setRawQuery(newValue)

        if(!newValue) {
            handleValue({ target: { value: null } }, 'request.delete.raw_query')
        } else if (isEdit) {
            handleValue({ target: { value: null } }, 'request.delete.raw_query')
        }
    }

    const handleSaveCode = () => {
        const codePrd = values?.request?.dev_mode?.prd.code
        if((editorCodePrd != codePrd)) handleValue(editorCodePrd, 'request.delete.dev_mode.prd.code')

        const codeHml = values?.request?.dev_mode?.hml.code
        if((editorCodeHml != codeHml)) handleValue(editorCodeHml, 'request.delete.dev_mode.hml.code')

        const codeSdb = values?.request?.dev_mode?.sdb.code
        if((editorCodeSdb != codeSdb)) handleValue(editorCodeSdb, 'request.delete.dev_mode.sdb.code')

        const codeDev = values?.request?.dev_mode?.dev.code
        if((editorCodeDev != codeDev)) handleValue(editorCodeDev, 'request.delete.dev_mode.dev.code')

        setSaveButtonDisabled(true)
        setAIButtonDisabled(false)

        if(!(editorCodePrd?.length 
            || editorCodeHml?.length 
            || editorCodeSdb?.length 
            || editorCodeDev?.length)) 
            setAIButtonDisabled(true)
    }

    useEffect(() => {
        setEditorCodePrd('')
        setEditorCodeHml('')
        setEditorCodeSdb('')
        setEditorCodeDev('')
    }, [appId])

    useEffect(() => {
        if(values?.request?.raw_query?.[0]?.length > 0) {
            setRawQuery(true)
        }

        if((values?.request?.dev_mode?.prd.code[0])) {
            const codePrd = values?.request?.dev_mode?.prd.code
            if((editorCodePrd != codePrd)) setEditorCodePrd(codePrd)
        }

        if((values?.request?.dev_mode?.hml.code[0])) {
            const codeHml = values?.request?.dev_mode?.hml.code
            if((editorCodeHml != codeHml)) setEditorCodeHml(codeHml)
        }

        if((values?.request?.dev_mode?.sdb.code[0])) {
            const codeSdb = values?.request?.dev_mode?.sdb.code
            if((editorCodeSdb != codeSdb)) setEditorCodeSdb(codeSdb)
        }

        if(values?.request?.dev_mode?.dev.code[0]) {
            const codeDev = values?.request?.dev_mode?.dev.code
            if((editorCodeDev != codeDev)) setEditorCodeDev(codeDev)
        }

        setSaveButtonDisabled(true)
        setEndpointDisabled(Boolean(values?.request?.status))
    }, [values, appId])

    const tabsEnv = [
        <>
            <h3 style={{ textAlign: 'left' }}>
                {Locale('production')} <Button 
                    style={{ float: 'right' }}
                    onClick={() => handleAI(editorCodePrd)}
                    disabled={aiButtonDisabled}    
                    color={'pink'}
                    variant={'solid'}
                >{Locale('analyze with simple ai')}</Button>
            </h3>
            <Editor
                language='javascript'
                value={editorCodePrd || ''}
                theme='vs-dark'
                height="75vh"  
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodePrd(newValue)
                }}
                options={{
                    autoIndent: "full"
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                {Locale('homolog')} <Button 
                    style={{ float: 'right' }}
                    onClick={() => handleAI(editorCodeHml)}
                    disabled={aiButtonDisabled}    
                    color={'pink'}
                    variant={'solid'}
                >{Locale('analyze with simple ai')}</Button>
            </h3>
            <Editor
                language='javascript'
                value={editorCodeHml || ''}
                theme='vs-dark'
                height="75vh"  
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodeHml(newValue)
                }}
                options={{
                    autoIndent: "full"
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                Sandbox <Button 
                    style={{ float: 'right' }}
                    onClick={() => handleAI(editorCodeSdb)}
                    disabled={aiButtonDisabled}    
                    color={'pink'}
                    variant={'solid'}
                >{Locale('analyze with simple ai')}</Button>
            </h3>
            <Editor
                language='javascript'
                value={editorCodeSdb || ''}
                theme='vs-dark'
                height="75vh"  
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodeSdb(newValue)
                }}
                options={{
                    autoIndent: "full"
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                {Locale('dev')} <Button 
                    style={{ float: 'right' }}
                    onClick={() => handleAI(editorCodeDev)}
                    disabled={aiButtonDisabled}    
                    color={'pink'}
                    variant={'solid'}
                >{Locale('analyze with simple ai')}</Button>
            </h3>
            <Editor
                language='javascript'
                value={editorCodeDev || ''}
                theme='vs-dark'
                height="75vh"  
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodeDev(newValue)
                }}
                options={{
                    autoIndent: "full"
                }}
            />
        </>
    ]

    const tabs = [
        <>
            <Divider orientation="left">
                {Locale('request')}
                <Tooltip placement="topLeft" title={Locale('request configurations')}>
                    <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                </Tooltip>    
            </Divider>
            
            <div id={'request-params'}>
                <Item label={(<>
                    {'RAW Query'}
                    <Tooltip placement="topLeft" title={Locale('you can create a RAW Query and pass the values with expression {variable_name}. If have authentication the system will try to fill in the variable and search the same name inside the session has been created with Reverse Authorization and if the variable is not finded the system will try to search in Query String. You don\'t need a RAW Query to utilize Query String.')}>
                        <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                    </Tooltip>
                    <Tooltip placement="topLeft" title={Locale('Raw query actived/deactived')}>
                        <Switch 
                            onChange={handleEnableRawQuery}
                            style={{ float: 'right', marginLeft: 10 }}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={rawQuery}
                        />
                    </Tooltip>
                    </>)}
                >
                    {
                        rawQuery && (
                            <>
                                <Input.TextArea 
                                    
                                    placeholder={'DELETE FROM {table} WHERE id = {id} AND user_id = {user_id}'}
                                    value={values?.request?.raw_query}
                                    onChange={(e) => handleValue(e, 'request.delete.raw_query')}
                                />
                                <Typography.Paragraph style={{ marginTop: 10, cursor: 'pointer' }}>Default vars: {'{table}, {id}'}</Typography.Paragraph>
                            </>
                        )
                    }
                </Item>
                <Row
                        gutter={{
                            xs: 8,
                            sm: 16,
                            md: 24,
                            lg: 32,
                        }}
                    >
                        <Col className="gutter-row" span={24}>
                            <Item label={(<>
                                {'Worker'}
                                <Tooltip placement="topLeft" title={Locale('execute a worker after the endpoint processing ends')}>
                                    <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                                </Tooltip>
                            </>)}>
                                <Select
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder={Locale('select worker')}
                                    loading={workers?.data?.length > 0 ? false : true}
                                    options={workers?.data?.length > 0 && [ { _id: null, name: Locale('none') }, ...workers?.data ]?.map(worker => {
                                        return {
                                            label: worker.name,
                                            value: worker._id
                                        }
                                    }) || []} 
                                    onChange={(e) => handleValue({ target: { value: e }}, 'request.delete.worker_id')}
                                    onInputKeyDown={(e) => e.preventDefault()}
                                    value={values?.request?.worker_id}
                                />
                            </Item>
                        </Col>
                    </Row>
            </div>
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                Dev Mode
            </h3>
            <h5 style={{ textAlign: 'left' }}>
                {/* Faça o clone do nosso <a href="https://github.com/codetec-repos/simplerest-dev-boilerplate">boilerplate</a> para auxiliar no desenvolvimento <br></br> */}
                {Locale('if has code in "Dev Mode" the "Simple Mode" is ignored')}<br></br>
                {Locale('The code always end with a return {object} or return {array} to fill the response')}<br></br>
                {Locale('after change, click in "save code" to confirm and after save a Pull Request will be open')}<br></br>
                {Locale('* in Sanbox use only the environment "Dev" for test')}
            </h5>
            <Input placeholder={Locale('type the Pull Request description')} value={values?.description} onChange={(e) => handleValue(e, 'request.delete.description')} />
            <Typography.Paragraph style={{ marginTop: 10, cursor: 'pointer' }}>Default vars: 
                <Tooltip placement="topLeft" title={Locale('process NodeJS variable')}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>process</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={Locale('require NodeJS variable')}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>require</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={Locale('Knex.js instance')}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>db</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={<pre>{`
const {
    body,
    queryString,
    session,
    simpleApiCallService: {
        call: async (apiId : String, method : String('list' | 'get' | 'post' | 'put' | 'delete'), data = {} : Object, params = {} : Object) => {}
    }
} = simplerest
                    `}</pre>}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>simplerest</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={<pre>{`
const {
    S3Service,
    SimplePayments,
    PDFGenerator,
    CSVGenerator,
    DateFormat,
    SimpleNotifications
} = simplelib
                `}</pre>}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>simplelib</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={<pre>{`
const {
    email: {
        config: {
            host: "",
            port: "",
            secure: false
        },
        auth: { 
            user: "",
            pass: ""
        },
        emailParams: {
            from: ""
        }
    },
    slack: {
        auth: {
            oauthToken: ""
        }
    },
    zenvia: {
        auth: {
            token: ""
        }
    },
    stripe: {
        auth: {
            secretKey: ""
        }
    },
    mercadoPago: {
        auth: {
            accessToken: "",
            webhook: ""
        }
    },
    aws: {
        auth: {
            region: "",
            accessKey: "",
            secretKey: ""
        }
    }
} = simplekeys
                `}</pre>}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>simplekeys</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={<pre>{`
const {
    list: async (pattern = '*' : String) => {},
    get: async (key : String) => {},
    set: async (key : String, value : Object, expires : Integer) => {},
    del: async (key : String) => {}
} = cache
                `}</pre>}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>cache</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={<pre>{`
const {
    sendMessage: async (workerId | queueName = '', message = {} : Object)
} = simplequeue
                `}</pre>}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>simplequeue</Typography.Text>
                </Tooltip>
            </Typography.Paragraph>
            <Tabs
                defaultActiveKey="2"
                items={[Locale('production'), Locale('homolog'), 'Sandbox', Locale('dev')].map((tabName, i) => {
                    return {
                        label: (
                        <span>
                            {tabName}
                        </span>
                        ),
                        key: i + 1,
                        children: tabsEnv[i],
                    }
                })}
            />
            <Button style={{ marginTop: 10, float: 'right' }} disabled={saveButtonDisabled} type="primary" shape="round" onClick={handleSaveCode}>{Locale('save code')}</Button>
        </>
    ]

    return (
        <div>
            <h3 style={{ textAlign: 'left' }}>
                <Tag color="error">DELETE</Tag> {isEdit ? (endpointName2?.length > 0 ? `/v1/${appId}${endpointName?.length > 0 ? `|${endpointName}` : ''}/{id}/${endpointName2}` : `/v1/${appId}${endpointName?.length > 0 ? `|${endpointName}` : ''}/{id}`) : `/v1/{app_id}|{endpoint_name}/{id}/?{endpoint_name2}`}
                <Tooltip placement="topLeft" title={Locale('endpoint actived/deactived')}>
                    <Switch 
                        onChange={handleEnableEndpoint}
                        style={{ float: 'right' }}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={endpointDisabled}
                    />
                </Tooltip>
            </h3>
        
            <Tabs
                defaultActiveKey="2"
                items={[ThunderboltOutlined, CoffeeOutlined].map((Icon, i) => {
                    const tabName = i == 0 ? 'Simple Mode' : 'Dev Mode' 
                    return {
                        label: (
                        <span>
                            <Icon />
                            {tabName}
                        </span>
                        ),
                        key: i + 1,
                        children: tabs[i],
                    }
                })}
            />
        </div>
    )
} 